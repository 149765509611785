<template>
    <div class="bitsea_my_profile_page">
        <div class="bitsea_my_profile_page_01">
            <Menu/>
        </div>

        <img id="bitsea_my_profile_page_bj" src="../../assets/images/bja.png" class="bitsea_bj_img">

        <div id="bitsea_my_profile_page_02" class="bitsea_my_profile_page_02">
            <div id="bitsea_my_profile_page_02_divs" class="bitsea_my_profile_page_02_divs">
                <div class="bitsea_my_profile_page_021">
                    <img class="bitsea_my_profile_page_0211" src="../../assets/images/goa.png"/>
                    <div class="bitsea_my_profile_page_0212">
                        <div class="bitsea_my_profile_page_02121">
                            {{ $store.state.accounts[0].toString().substring(0, 6) }}
                        </div>
                        <div class="bitsea_my_profile_page_02122">
                            <div class="bitsea_my_profile_page_021221">
                                {{$store.state.accounts.length === 0 ? 'Connect Wallet' : cutAccount($store.state.accounts[0], 5)}}
                            </div>
                            <img src="../../assets/images/Icon3.png" @click="copyValue($store.state.accounts[0])"/>
                        </div>
                        <div class="bitsea_my_profile_page_02123">
                            <!--                            <img src="../../assets/images/telegram1.png"/>-->
                            <!--                            <img src="../../assets/images/Icon.png"/>-->
                            <!--                            <img src="../../assets/images/Vector4.png" width="14px"/>-->
                        </div>
                    </div>
                    <div class="bitsea_my_profile_page_0213">
                        <!-- <div class="bitsea_my_profile_page_02131">
                            Balance
                        </div> -->
                        <div class="bitsea_my_profile_page_02132">
                            <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                            <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                            <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
                            <div class="bitsea_my_profile_page_021321">
                                {{ setDecimal(myBalance, 4) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mob">
                    <div class="bitsea_my_profile_page_022_all_00" @click="myProfileDrawer = true">
                        <div>Please Select Collections</div>
                        <img src="../../assets/images/switch-horizontal-01.png"/>
                    </div>
                </div>

                <div class="bitsea_my_profile_page_022">
                    <div class="bitsea_my_profile_page_0221">Collections</div>

                    <div class="bitsea_my_profile_page_0222">
                        <div @click="setAllCollectionsType(1)" :class="allCollectionsType === 1 ? 'bitsea_my_profile_page_0222_select bitsea_my_profile_page_02221' : 'bitsea_my_profile_page_02221'">Inventory</div>
                        <div @click="setAllCollectionsType(3)" :class="allCollectionsType === 3 ? 'bitsea_my_profile_page_0222_select bitsea_my_profile_page_02221' : 'bitsea_my_profile_page_02221'">Listings</div>
                        <div @click="setAllCollectionsType(2)" :class="allCollectionsType === 2 ? 'bitsea_my_profile_page_0222_select bitsea_my_profile_page_02222' : 'bitsea_my_profile_page_02222'">History</div>
                    </div>
                </div>

                <div class="bitsea_my_profile_page_023">
                    <div class="bitsea_my_profile_page_023a" v-if="allCollectionsType === 1 || allCollectionsType === 3">
                        <div class="bitsea_my_profile_page_023a1">
                            <div class="bitsea_my_profile_page_023a11">
                                <img src="../../assets/images/Icon2.png"/>
                                <input placeholder="Search" v-model="searchKey" @input="searchNft(searchKey)" @keyup="searchNft(searchKey)"/>
                            </div>
                            <div class="bitsea_my_profile_page_023a12">
                                <div class="bitsea_my_profile_page_023a121">
                                    <div class="bitsea_my_profile_page_023a1211">
                                        Name
                                    </div>
                                    <div class="bitsea_my_profile_page_023a1212">
                                        Value
                                    </div>
                                </div>

                                <div class="bitsea_my_profile_page_023a122">
                                    <div class="bitsea_my_profile_page_023a1221" :style="selectNftIndex === key ? 'background: rgba(17, 17, 17, 0.35); border-radius: 5px;' : ''" v-if="proBitseaNfts.length > 0" v-show="(searchKey && searchKey !== null && searchKey !== '') ? (obj.name.toString().indexOf(searchKey.toString()) >= 0) : true" v-for="(obj, key) in proBitseaNfts" :key="key" @click="getMyNftListData(obj, key, 1)">
                                        <div class="bitsea_my_profile_page_023a12211">
                                            <img :src="obj.img"/>
                                            <div class="bitsea_my_profile_page_023a122111">
                                                <div class="bitsea_my_profile_page_023a1221111">
                                                    {{ obj.name }}
                                                </div>
                                                <div class="bitsea_my_profile_page_023a1221112" v-if="myNftAllNum.length >= proBitseaNfts.length">
                                                    Listed {{myNftAllNum[key].goodsNum}}/{{(myNftAllNum[key].nftNum + myNftAllNum[key].goodsNum)}}
                                                </div>
                                                <div class="bitsea_my_profile_page_023a1221112" v-else>
                                                    Listed 0/0
                                                </div>
                                            </div>
                                        </div>

                                        <div class="bitsea_my_profile_page_023a12212">
                                            <div class="bitsea_my_profile_page_023a122121">
                                                <!--                                                $9,945.1-->
                                            </div>
                                            <div class="bitsea_my_profile_page_023a122122">
                                                <!--                                                +3.57%-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bitsea_my_profile_page_023a2">

                            <div class="bitsea_my_profile_page_batch_listing_all">
                                <div v-show="allCollectionsType === 1" @click="onBatchListing(myNftObjListData)" class="bitsea_my_profile_page_batch_listing_011 mob">List multiple</div>
                                <div v-show="allCollectionsType === 3" @click="toBatchDelisting()" class="bitsea_my_profile_page_batch_listing_011 mob">Cancel multiple</div>
                                <el-checkbox :indeterminate="invIsIndeterminate" v-model="invCheckAll" @change="invHandleCheckAllChange">All</el-checkbox>
                                <div v-show="allCollectionsType === 1" @click="onBatchListing(myNftObjListData)" class="bitsea_my_profile_page_batch_listing_012 com">List multiple</div>
                                <div v-show="allCollectionsType === 3" @click="toBatchDelisting()" class="bitsea_my_profile_page_batch_listing_012 com">Cancel multiple</div>
                            </div>

                            <div class="bitsea_my_profile_page_023a21">
                                {{ myNftItems }} items
                            </div>
                            <div class="bitsea_my_profile_page_023a22">

                                <el-checkbox-group v-model="invCheckedCities" @change="invHandleCheckedCitiesChange">
                                    <div class="bitsea_my_profile_page_023a221" v-if="myNftObjListData.length > 0" v-for="(myObj, key) in myNftObjListData" :key="key">
                                        <img src="../../assets/images/homepage_bannercard.png" class="bitsea_my_profile_page_023a2211_bj"/>

                                        <img class="bitsea_my_profile_page_023a2211" :src="myObj.img"/>

                                        <div class="bitsea_my_profile_page_023a2211_checkbox">
                                            <el-checkbox v-if="allCollectionsType === 1" :label="parseInt(myObj.nftId)" />
                                            <el-checkbox v-if="allCollectionsType === 3" :label="parseInt(myObj.goodsId)" />
                                        </div>

                                        <div class="bitsea_my_profile_page_023a2212">
                                            <div class="bitsea_my_profile_page_023a22121">{{ myObj.name }} #{{ myObj.nftId }}</div>
                                            <div class="bitsea_my_profile_page_023a22122" v-show="allCollectionsType === 1"></div>
                                            <div class="bitsea_my_profile_page_023a22122" v-show="allCollectionsType === 3">
                                                Price:
                                                {{ myObj.nftPrice ? setDecimal(myObj.nftPrice/1e18, 6) : 0 }}
                                                {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                                                {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                                                {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
                                            </div>
                                        </div>

                                        <div v-show="allCollectionsType === 1" class="bitsea_my_profile_page_023a2213_alls">
                                            <div class="bitsea_my_profile_page_023a2213" @click="onList(myObj)">LIST</div>
                                            <div class="bitsea_my_profile_page_023a2213_a" @click="onTransfer(myObj)">
                                                <img src="../../assets/images/Frame14671.png"/>
                                                <!--                                            <div>Send</div>-->
                                            </div>
                                        </div>

                                        <div v-show="allCollectionsType === 3" class="bitsea_my_profile_page_023a2213_c" @click="offSell(myObj.goodsId)">CANCEL</div>
                                        <!--                                    <div class="bitsea_my_profile_page_023a2213" style="background: #505052; cursor: not-allowed;">LIST</div>-->
                                    </div>
                                </el-checkbox-group>

                                <div class="bitsea_my_profile_page_023a222" v-if="myNftObjListData.length <= 0">
                                    {{ isMyNftObjListLoading ? "Loading..." : "Not Data" }}
                                </div>
                            </div>
                            <div v-if="myNftObjListData.length > 0" class="bitsea_my_profile_page_023a23">
                                <el-pagination
                                        background
                                        @prev-click="toPage"
                                        @next-click="toPage"
                                        @current-change="toPage"
                                        layout="prev, pager, next"
                                        :page-size="pageSize"
                                        :current-page="pageNum"
                                        :total="myNftItems">
                                </el-pagination>
                            </div>
                        </div>
                    </div>

                    <div class="bitsea_my_profile_page_023b" v-if="allCollectionsType === 2">
                        <div class="bitsea_my_profile_page_023b1">
                            <div class="bitsea_my_profile_page_023b11"></div>
                            <div class="bitsea_my_profile_page_023b12">
                                Item
                            </div>
                            <div class="bitsea_my_profile_page_023b13">
                                Price
                            </div>
                            <div class="bitsea_my_profile_page_023b14">
                                Quantity
                            </div>
                            <div class="bitsea_my_profile_page_023b15">
                                From
                            </div>
                            <div class="bitsea_my_profile_page_023b16">
                                To
                            </div>
                            <div class="bitsea_my_profile_page_023b17">
                                Time
                            </div>
                        </div>
                        <div class="bitsea_my_profile_page_023b2">
                            <div class="bitsea_my_profile_page_023b21" v-if="nftTransactionDataList.length > 0" v-for="(obj, key) in nftTransactionDataList" :key="key">
                                <div class="bitsea_my_profile_page_023b211" v-if="obj.CBuyer">
                                    <img v-show="obj.CBuyer.toLowerCase() !== $store.state.accounts[0].toLowerCase()" src="../../assets/images/switch-horizontal-01.png"/>
                                    <img v-show="obj.CBuyer.toLowerCase() === $store.state.accounts[0].toLowerCase()" src="../../assets/images/Frame.png"/>
                                    <div class="bitsea_my_profile_page_023b2111">
                                        {{obj.CBuyer.toLowerCase() === $store.state.accounts[0].toLowerCase() ? 'Buy' : 'Sold'}}
                                    </div>
                                </div>
                                <div class="bitsea_my_profile_page_023b212" v-if="obj.CAddress.toUpperCase() !== '0X57C46671A01AAD9359D97F844916CCAB4BF6713C'">
                                    <img :src="obj.CUri"/>
                                </div>
                                <div class="bitsea_my_profile_page_023b212" v-if="obj.CAddress.toUpperCase() === '0X57C46671A01AAD9359D97F844916CCAB4BF6713C'">
                                    <img src="https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png"/>
                                </div>
                                <div class="bitsea_my_profile_page_023b213">
                                    {{parseInt(obj.CPrice) === 0 ? 0 : setDecimal(obj.CPrice / 1e18, 6)}}
                                    {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                                    {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                                    {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
                                </div>
                                <div class="bitsea_my_profile_page_023b214">
                                    1
                                </div>
                                <div class="bitsea_my_profile_page_023b215" v-if="obj.COwner">
                                    {{obj.COwner.toLowerCase() === $store.state.accounts[0].toLowerCase() ? 'You' : cutAccount(obj.COwner, 5)}}
                                </div>
                                <div class="bitsea_my_profile_page_023b216" v-if="obj.CBuyer">
                                    {{obj.CBuyer.toLowerCase() === $store.state.accounts[0].toLowerCase() ? 'You' : cutAccount(obj.CBuyer, 5)}}
                                </div>
                                <div class="bitsea_my_profile_page_023b217">
                                    {{obj.CDealTime ? new Date(obj.CDealTime * 1000).toUTCString() : '--'}}
                                </div>
                            </div>
                            <div class="bitsea_my_profile_page_023b22" v-if="nftTransactionDataList.length <= 0">
                                {{ isNftTransactionLoading ? "Loading..." : "Not Data" }}
                            </div>
                        </div>
                    </div>

                    <div v-if="allCollectionsType === 2 && nftTransactionDataList.length > 0" class="bitsea_my_profile_page_023b3">
                        <el-pagination
                                background
                                @prev-click="getNftTransaction"
                                @next-click="getNftTransaction"
                                @current-change="getNftTransaction"
                                layout="prev, pager, next"
                                :page-size="100"
                                :current-page="nftTransactionPageNum"
                                :total="nftTransactionTotal">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <div class="bitsea_my_profile_page_03">
                <Bottom/>
            </div>
        </div>

        <div class="my_profile_list_time_dialog_modal" v-if="profileListTimeDialog">
            <div class="my_profile_list_time_dialog_div">
                <div class="my_profile_list_time_dialog_div_01">
                    <div>List Item</div>
                    <img src="../../assets/images/icon_close.png" @click="closeListItem"/>
                </div>

                <div class="my_profile_list_time_dialog_div_02">
                    <div class="my_profile_list_time_dialog_div_021">
                        <div class="my_profile_list_time_dialog_div_0211">
                            <img :src="listItemObj.img"/>
                            <div class="my_profile_list_time_dialog_div_02111">
                                <div v-if="!isOnBatchListing" class="my_profile_list_time_dialog_div_021111">
                                    {{listItemObj.name}} #{{listItemObj.nftId}}
                                </div>
                                <div v-if="isOnBatchListing" class="my_profile_list_time_dialog_div_021111">
                                    {{listItemObj.name}} #{{invCheckedCities}}
                                </div>
                                <div class="my_profile_list_time_dialog_div_021112">
                                    <!--                                    Bitcoin {{listItemObj.nftUri}}-->
                                </div>
                            </div>
                        </div>
                        <div class="my_profile_list_time_dialog_div_0212">
                            <div class="my_profile_list_time_dialog_div_02121">
                                <!--                                Listing price-->
                            </div>
                            <!--                            <div class="my_profile_list_time_dialog_div_02122">-->
                            <!--                                {{listItemObj.listingPrice ? setDecimal(listItemObj.listingPrice/1e18, 6) : "&#45;&#45;"}}  BTC-->
                            <!--                            </div>-->
                            <div class="my_profile_list_time_dialog_div_02122">
                            </div>
                        </div>
                    </div>

                    <div class="my_profile_list_time_dialog_div_022">
                        <div class="my_profile_list_time_dialog_div_0221">
                            <div class="my_profile_list_time_dialog_div_02211">
                                <div class="my_profile_list_time_dialog_div_022111">
                                    &nbsp;&nbsp;&nbsp; Floor {{setDecimal(listItemObj.floorPrice/1e18, 6)}}
                                    {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                                    {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                                    {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                                <div class="my_profile_list_time_dialog_div_022112">
                                    Maker Fee
                                </div>
                                <div class="my_profile_list_time_dialog_div_022113">
                                    Your  earnings
                                </div>
                            </div>
                            <div class="my_profile_list_time_dialog_div_02212">
                                <div class="my_profile_list_time_dialog_div_022121">
                                    <div class="my_profile_list_time_dialog_div_0221211">
                                        <input placeholder="Amount" type="number" v-model="priceAmount" @input="setYourEarnings(priceAmount)" @keyup="setYourEarnings(priceAmount)"/>
                                    </div>
                                    <div class="my_profile_list_time_dialog_div_0221212">
                                        <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                                        <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                                        <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
                                    </div>
                                </div>
                                <div class="my_profile_list_time_dialog_div_022122">
                                    {{listItemObj.makerFee}}%
                                </div>
                                <div class="my_profile_list_time_dialog_div_022123">
                                    {{setDecimal(listItemObj.yourEarnings, 6)}}
                                </div>
                            </div>
                        </div>

                        <div class="my_profile_list_time_dialog_div_0222">
                            By clicking “list”,you agree to the BitSea Terms of Service.
                        </div>
                    </div>

                    <!--                    <div v-show="listItemObj.isApprovedForAll" class="my_profile_list_time_dialog_div_023" @click="toCompleteListing(listItemObj)">-->
                    <!--                        Complete Listing-->
                    <!--                    </div>-->
                    <!--                    <div v-show="!listItemObj.isApprovedForAll" class="my_profile_list_time_dialog_div_023" @click="toSetApprovalForAll(listItemObj)">-->
                    <!--                        Approved-->
                    <!--                    </div>-->

                    <div v-show="!isOnBatchListing" class="my_profile_list_time_dialog_div_023" @click="toSetApprovalForAll(listItemObj)">
                        Complete Listing
                    </div>
                    <div v-show="isOnBatchListing" class="my_profile_list_time_dialog_div_023" @click="toBatchListing(listItemObj)">
                        List multiple
                    </div>
                </div>
            </div>
        </div>

        <div class="my_profile_list_time_dialog_modal" v-if="transferDialog">
            <div class="my_profile_list_time_dialog_div" style="height: 390px !important;">
                <div class="my_profile_list_time_dialog_div_01">
                    <div>Transfer</div>
                    <img src="../../assets/images/icon_close.png" @click="closeTransferDialog"/>
                </div>

                <div class="my_profile_list_time_dialog_div_02">
                    <div class="my_profile_list_time_dialog_div_021" style="border-bottom: 0 solid #646464 !important;">
                        <div class="my_profile_list_time_dialog_div_0211">
                            <img :src="transferObj.img"/>
                            <div class="my_profile_list_time_dialog_div_02111">
                                <div class="my_profile_list_time_dialog_div_021111">
                                    {{transferObj.name}} #{{transferObj.nftId}}
                                </div>
                                <div class="my_profile_list_time_dialog_div_021112">
                                    <!--                                    Bitcoin {{listItemObj.nftUri}}-->
                                </div>
                            </div>
                        </div>
                        <div class="my_profile_list_time_dialog_div_0212">
                            <div class="my_profile_list_time_dialog_div_02121">
                            </div>
                            <div class="my_profile_list_time_dialog_div_02122">
                            </div>
                        </div>
                    </div>

                    <div class="my_profile_list_time_dialog_div_022">
                        <div class="my_profile_list_time_dialog_div_022_transfer">
                            <input placeholder=" Receive Address" v-model="receiveAddress" class="input_receive_address"/>
                        </div>
                    </div>

                    <div class="my_profile_list_time_dialog_div_023" style="margin-top: 30px !important;" @click="toTransfer(transferObj)">
                        Confirm
                    </div>
                </div>
            </div>
        </div>

        <el-drawer
                title="MyProfile"
                direction="rtl"
                size="280px"
                :append-to-body="true"
                :visible.sync="myProfileDrawer"
                :with-header="false">
            <div class="my_profile_page_drawer">
                <div class="my_profile_page_drawer_01">
                    <img src="../../assets/images/close.png" @click="myProfileDrawer = false"/>
                </div>

                <div class="my_profile_page_drawer_02">
                    Collections
                </div>

                <div class="my_profile_page_drawer_alls">

                    <div class="bitsea_my_profile_page_023a11">
                        <img src="../../assets/images/Icon2.png"/>
                        <input placeholder="Search" v-model="searchKey" @input="searchNft(searchKey)" @keyup="searchNft(searchKey)"/>
                    </div>
                    <div class="bitsea_my_profile_page_023a12">
                        <div class="bitsea_my_profile_page_023a121">
                            <div class="bitsea_my_profile_page_023a1211">
                                Name
                            </div>
                            <div class="bitsea_my_profile_page_023a1212">
                                Value
                            </div>
                        </div>

                        <div class="bitsea_my_profile_page_023a122">
                            <div class="bitsea_my_profile_page_023a1221" :style="selectNftIndex === key ? 'background: rgb(211 129 23); border-radius: 5px;' : ''" v-if="proBitseaNfts.length > 0" v-show="(searchKey && searchKey !== null && searchKey !== '') ? (obj.name.toString().indexOf(searchKey.toString()) >= 0) : true" v-for="(obj, key) in proBitseaNfts" :key="key" @click="getMyNftListData(obj, key, 1)">
                                <div class="bitsea_my_profile_page_023a12211">
                                    <img :src="obj.img"/>
                                    <div class="bitsea_my_profile_page_023a122111">
                                        <div class="bitsea_my_profile_page_023a1221111">
                                            {{ obj.name }}
                                        </div>
                                        <div class="bitsea_my_profile_page_023a1221112" v-if="myNftAllNum.length >= proBitseaNfts.length">
                                            Listed {{myNftAllNum[key].goodsNum}}/{{(myNftAllNum[key].nftNum + myNftAllNum[key].goodsNum)}}
                                        </div>
                                        <div class="bitsea_my_profile_page_023a1221112" v-else>
                                            Listed 0/0
                                        </div>
                                    </div>
                                </div>

                                <div class="bitsea_my_profile_page_023a12212">
                                    <div class="bitsea_my_profile_page_023a122121">
                                        <!--                                                $9,945.1-->
                                    </div>
                                    <div class="bitsea_my_profile_page_023a122122">
                                        <!--                                                +3.57%-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </el-drawer>

    </div>
</template>

<script>
    import Menu from "../../components/Menu";
    import Bottom from "../../components/Bottom";
    import toolUtils from "../../tools/toolUtils";
    import { mixin1 } from "../../tools/mixins";
    import * as metaMaskTools from "../../tools/chain";

    import transfer from "../../tools/transfer";
    import {
        BigNumber,
        BigNumberStr,
        BigNumberMul,
        BigNumberDiv,
        BigNumberAdd,
        BigNumberSub,
    } from '../../tools/index';
    import {
        debug
    } from "../../config";
    import {
        ipfsUrl,
        openUrlSLD,
        bitseaNfts,
        controlAddress,
        shopAddress,
        monsterLandAddress
    } from "../../tools/projectConfig";
    import { cutAccount } from "../../tools";

    export default {
        name: "Index",
        components: { Menu, Bottom },
        mixins: [ mixin1 ],
        data() {
            return {
                toolUtils,
                // bitseaNfts,
                proBitseaNfts: [],
                // shopAddress,
                myProfileDrawer: false,
                searchKey: null,
                bitseaPageWidth: null,
                bitseaPageHeight: 0,
                allCollectionsType: 1,
                transferDialog: false,
                receiveAddress: null,
                transferObj: {
                    name: null,
                    img: null,
                    address: null,
                    nftId: 0,
                    nftUri: null,
                },
                profileListTimeDialog: false,
                myBalance: 0,
                myNftItems: 0,
                pageNum: 1,
                pageSize: 10,
                selectNftIndex: 0,
                selectNftObj: null,
                isMyNftObjListLoading: false,
                myNftObjListData: [],
                priceAmount: null,
                listItemObj: {
                    name: null,
                    img: null,
                    address: null,
                    nftId: 0,
                    nftUri: null,
                    nftType: 0,
                    floorPrice: 0,
                    listingPrice: 0,
                    makerFee: 0,
                    yourEarnings: 0,
                    isApprovedForAll: false,
                },
                nftTransactionPageNum: 1,
                nftTransactionTotal: 0,
                isNftTransactionLoading: false,
                nftTransactionDataList: [],
                myNftAllNum: [],
                isOnBatchListing: false,
                invCheckAll: false,
                invIsIndeterminate: false,
                invCities: [],
                invCheckedCities: [],
            }
        },
        watch: {},
        created() {
            transfer.$on("myProfileInitData", (data) => {
                this.myProfileInitData(data);
            });

            this.initProData();

            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000);
        },
        mounted() {
            this.initProData();

            setTimeout(() => {
                this.initPageData();
            }, 500);

            window.onresize = () => {
                return (() => {
                    this.setBitseaPageHeight();
                })();
            }

            setTimeout(() => {
                this.setBitseaPageHeight();
            }, 10);
            setTimeout(() => {
                this.setBitseaPageHeight();
            }, 300);
        },
        destroyed() {
        },
        methods: {
            async onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });

                await this.getBalance();
                await this.getMyNftAllNum();
                if (bitseaNfts.length > 0) {
                    await this.getMyNftListData(bitseaNfts[this.selectNftIndex], this.selectNftIndex, this.pageNum);
                }

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 10);
            },
            async initProData() {
                this.proBitseaNfts = bitseaNfts;
            },
            async myProfileInitData() {
                this.myNftObjListData = [];
                this.nftTransactionDataList = [];
                this.nftTransactionPageNum = 1;
                this.pageNum = 1;
                this.selectNftIndex = 0;
                await this.initPageData();

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 500);
            },
            async initPageData() {
                await this.initProData();

                await metaMaskTools.initParams().then(() => {});

                await this.getBalance();
                await this.getMyNftAllNum();
                if (bitseaNfts.length > 0) {
                    await this.getMyNftListData(bitseaNfts[this.selectNftIndex], this.selectNftIndex, this.pageNum);
                }

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 10);
            },
            setBitseaPageHeight() {
                let divWidth = document.getElementById("bitsea_my_profile_page_02").clientWidth;
                let divHeight = document.getElementById("bitsea_my_profile_page_02").clientHeight;
                let divHeights = document.getElementById("bitsea_my_profile_page_02_divs").clientHeight;

                if (divHeights + 200 > divHeight) {
                    document.getElementById("bitsea_bottom_page").style.position = "relative";
                } else {
                    document.getElementById("bitsea_bottom_page").style.position = "absolute";
                }

                let bjActualWidth = ((divHeight / 1158) * 1920);
                if (divWidth < bjActualWidth) {
                    $('#bitsea_my_profile_page_bj').animate({ width : bjActualWidth }, 0);
                } else {
                    $('#bitsea_my_profile_page_bj').animate({ width : divWidth }, 0);
                }
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            setDecimal(numVal, numDecimalMax) {
                return toolUtils.setDecimal(numVal, numDecimalMax);
            },
            formatTimestamp(dateTime) {
                return toolUtils.formatTimestamp(dateTime);
            },
            copyValue(val) {
                if (val) {
                    toolUtils.copyValue(val);
                    this.$message.success('Copy successful!');
                } else {
                    this.$message.error('No content copied!');
                }
            },
            async getBalance() {
                if (this.$store.state.accounts.length > 0) {
                    try {
                        await metaMaskTools.getBalance(this.$store.state.accounts[0]).then(async (data) => {
                            this.myBalance = data / 1e18;
                        });
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    setTimeout(async () => {
                        await this.getBalance();
                    }, 10000);
                }

                setTimeout(async() => {
                    await this.getBalance();
                }, 60000);
            },
            async setAllCollectionsType(index) {
                if (parseInt(this.allCollectionsType) !== parseInt(index)) {
                    this.myNftObjListData = [];

                    this.initCheckbox();
                }

                this.isMyNftObjListLoading = false;
                this.isNftTransactionLoading = false;

                this.allCollectionsType = parseInt(index);

                if (this.selectNftObj === null) {
                    this.pageNum = 1;
                    this.selectNftIndex = 0;
                    this.selectNftObj = bitseaNfts[0];
                }
                await this.getMyNftListData(this.selectNftObj, this.selectNftIndex, this.pageNum);

                setTimeout(() => {
                    this.setBitseaPageHeight();
                }, 10);
                setTimeout(() => {
                    this.setBitseaPageHeight();
                }, 300);
            },
            async getNftTransaction(pageNum) {
                if (this.nftTransactionPageNum !== parseInt(pageNum)) {
                    this.nftTransactionDataList = [];
                }
                this.isNftTransactionLoading = true;
                this.nftTransactionPageNum = parseInt(pageNum);

                if (this.$store.state.accounts.length > 0) {
                    await this.$http.listdeal(pageNum, 21, this.$store.state.accounts[0]).then(async (res) => {
                        if (res) {
                            this.nftTransactionTotal = parseInt(res.count);
                            this.nftTransactionDataList = res.data;
                        }
                    });

                    // let ids = [];
                    // await metaMaskTools.erc721BasicPublicCall(nftAddress, "balanceOf", [this.$store.state.accounts[0]]).then(async (balance) => {
                    //     this.myNftItems = parseInt(balance);
                    //
                    //     if (this.myNftItems > 0) {
                    //         for (let i = 0; i < this.myNftItems; i++) {
                    //             await metaMaskTools.erc721BasicPublicCall(nftAddress, "tokenOfOwnerByIndex", [this.$store.state.accounts[0], i]).then(async (id) => {
                    //                 if (id && parseInt(id) > 0) {
                    //                     ids.push(id);
                    //                 }
                    //             });
                    //         }
                    //     }
                    // });
                }

                this.isNftTransactionLoading = false;
            },
            async getMyNftAllNum() {
                try {
                    let myNftAllNumArr = [];

                    if (this.$store.state.accounts.length > 0) {
                        for (let i = 0; i < bitseaNfts.length; i++) {
                            let obj = {
                                goodsNum: 0,
                                nftNum: 0
                            }

                            if (bitseaNfts[i].name === "MonsterLand404") {
                                // await metaMaskTools.getBalanceOf([this.$store.state.accounts[0]]).then(async (nftNum) => {
                                //     obj.nftNum = parseInt(parseInt(nftNum) / 1e18);
                                // });

                                await metaMaskTools.myNftNum([]).then(async (nftNum) => {
                                    obj.nftNum = parseInt(nftNum);
                                });
                            } else {
                                await metaMaskTools.erc721BasicPublicCall(bitseaNfts[i].address, "balanceOf", [this.$store.state.accounts[0]]).then(async (nftNum) => {
                                    obj.nftNum = parseInt(nftNum);
                                });
                            }

                            // await metaMaskTools.getShopBalanceOf([this.$store.state.accounts[0]]).then(async (goodsNum) => {
                            //     obj.goodsNum = parseInt(goodsNum);
                            // });

                            await this.$http.listgoods(1, 10, '', bitseaNfts[i].address, this.$store.state.accounts[0]).then(async (res) => {
                                if (res.count) {
                                    obj.goodsNum = parseInt(res.count);
                                } else {
                                    obj.goodsNum = 0;
                                }
                            });

                            myNftAllNumArr.push(obj);
                        }
                    }

                    this.myNftAllNum = myNftAllNumArr;
                } catch (err) {
                    console.log(err);
                }

                setTimeout(() => {
                    this.getMyNftAllNum();
                }, 60000);
            },
            async searchNft(key) {},
            toPage(pageNum) {
                this.getMyNftListData(this.selectNftObj, this.selectNftIndex, pageNum);
            },
            async getMyNftListData(obj, key, page) {
                this.myProfileDrawer = false;

                // if (obj.name === "MonsterLand404") {
                //     this.$message.warning('Coming soon!');
                //     return;
                // }

                this.initCheckbox();

                if(this.allCollectionsType === 1) {
                    if (parseInt(this.selectNftIndex) !== parseInt(key)) {
                        this.myNftObjListData = [];
                    }
                }

                this.selectNftIndex = key;
                this.selectNftObj = obj;

                if(this.allCollectionsType === 1 || this.allCollectionsType === 3) {
                    if (parseInt(this.pageNum) !== parseInt(page)) {
                        this.myNftObjListData = [];
                    }
                }

                this.pageNum = page;

                if (this.$store.state.accounts.length > 0) {
                    if (this.allCollectionsType === 1) {
                        this.pageSize = 10;
                        this.isMyNftObjListLoading = true;
                        try {
                            if (obj.name === "MonsterLand404") {
                                // await metaMaskTools.getBalanceOf([this.$store.state.accounts[0]]).then(async (nftNum) => {
                                //     this.myNftItems = parseInt(parseInt(nftNum) / 1e18);
                                // });

                                await metaMaskTools.myNftNum([]).then(async (nftNum) => {
                                    this.myNftItems = parseInt(nftNum);
                                });

                                await metaMaskTools.getMyNftByPage([page]).then(async (res) => {
                                    this.invCities = [];
                                    let myNftObjList = [];
                                    for (let i = 0; i < res[0].length; i++) {
                                        if (parseInt(res[0][i]) > 0) {
                                            myNftObjList.push({
                                                name: obj.name,
                                                img: res[2][i],
                                                address: obj.address,
                                                nftId: res[0][i],
                                                nftType: res[1][i],
                                                nftUri: res[2][i],
                                            });

                                            this.invCities.push(parseInt(res[0][i]));
                                        } else {
                                            break;
                                        }
                                    }

                                    this.myNftObjListData = myNftObjList;
                                });
                            } else if (
                                obj.name === "GenesisBox" ||
                                obj.name === "RyeharvestBadge"
                            ) {
                                this.pageSize = 50;
                                let unique = 0;

                                if (parseInt(this.pageNum) > 1 && sessionStorage.getItem("uniqueToken")) {
                                    unique = parseInt(sessionStorage.getItem("uniqueToken"));
                                } else {
                                    unique = 0;
                                }

                                await metaMaskTools.erc721BasicPublicCall(obj.address, "balanceOf", [this.$store.state.accounts[0]]).then(async (balance) => {
                                    this.myNftItems = parseInt(balance);
                                });

                                this.invCities = [];
                                let myNftObjList = [];

                                await this.$http.getUserGbxList(obj.address, this.$store.state.accounts[0], unique).then(async (res) => {
                                    if (res && res.items.length) {
                                        for (let i = 0; i < res.items.length; i++) {
                                            if (parseInt(res.items[i].id) > 0) {
                                                myNftObjList.push({
                                                    name: obj.name,
                                                    img: obj.img,
                                                    address: obj.address,
                                                    nftId: res.items[i].id,
                                                    nftType: 1,
                                                    nftUri: res.items[i].image_url,
                                                });

                                                this.invCities.push(parseInt(res.items[i].id));
                                            } else {
                                                break;
                                            }
                                        }
                                    }

                                    if (res && res.next_page_params) {
                                        sessionStorage.setItem("uniqueToken", res.next_page_params.unique_token);
                                    } else {
                                        sessionStorage.removeItem("uniqueToken");
                                    }

                                    this.myNftObjListData = myNftObjList;
                                });
                            } else if (obj.name === "BlockHeadz" || obj.name === "BitcoinLoot NFT") {
                                this.pageSize = 50;

                                this.invCities = [];
                                let myNftObjList = [];

                                let maxTotal = 0;
                                let take = 50;

                                let cursor = null;
                                if (parseInt(this.pageNum) > 1 && sessionStorage.getItem("cursorToken")) {
                                    cursor = parseInt(sessionStorage.getItem("cursorToken"));
                                } else {
                                    cursor = null;
                                }

                                // let userAddress = "0x502fD9Ee712656e38c48af83A8F8541A72f3C67E";
                                let userAddress = this.$store.state.accounts[0];

                                let countRes = 0;

                                await this.$http.getAddressTokenBalance(null, userAddress, null, null).then(async (res) => {
                                    if (res && res.result.data.json.length) {
                                        for (let i = 0; i < res.result.data.json.length; i++) {
                                            if (obj.name === res.result.data.json[i].name) {
                                                this.myNftItems = parseInt(res.result.data.json[i].balance);
                                                break;
                                            } else {
                                                this.myNftItems = 0;
                                            }
                                        }

                                        if (this.myNftItems > 0) {
                                            maxTotal = this.myNftItems >= this.pageSize ? this.pageSize : this.myNftItems;

                                            while (myNftObjList.length < maxTotal && countRes <= 9) {
                                                await this.$http.getAddressTokenTxList(null, userAddress, take, cursor).then(async (resultData) => {
                                                    if (resultData && resultData.result.data.json.nextCursor) {
                                                        cursor = parseInt(resultData.result.data.json.nextCursor);
                                                        sessionStorage.setItem("cursorToken", cursor);
                                                    } else {
                                                        cursor = null;
                                                        sessionStorage.removeItem("cursorToken");
                                                    }

                                                    if (resultData && resultData.result.data.json.list.length) {
                                                        for (let j = 0; j < resultData.result.data.json.list.length; j++) {
                                                            if (obj.name === resultData.result.data.json.list[j].name) {
                                                                let isPush = true;
                                                                if (myNftObjList.length > 0) {
                                                                    for (let x = 0; x < myNftObjList.length; x++) {
                                                                        if (parseInt(myNftObjList[x].nftId) === parseInt(resultData.result.data.json.list[j].token_id)) {
                                                                            isPush = false;
                                                                            break;
                                                                        }
                                                                    }
                                                                }

                                                                if (isPush) {
                                                                    if (obj.name === "BlockHeadz") {
                                                                        myNftObjList.push({
                                                                            name: obj.name,
                                                                            img: "https://b2-static.bsquared.network/assets/nft/images/BlockHeadz/" + resultData.result.data.json.list[j].token_id + ".png",
                                                                            address: obj.address,
                                                                            nftId: resultData.result.data.json.list[j].token_id,
                                                                            nftType: 1,
                                                                            nftUri: obj.img,
                                                                        });
                                                                    } else if (obj.name === "BitcoinLoot NFT") {
                                                                        myNftObjList.push({
                                                                            name: obj.name,
                                                                            img: "https://static.bitcoinloot.co/loot/loot_" + resultData.result.data.json.list[j].token_id + ".png",
                                                                            address: obj.address,
                                                                            nftId: resultData.result.data.json.list[j].token_id,
                                                                            nftType: 1,
                                                                            nftUri: obj.img,
                                                                        });
                                                                    }

                                                                    this.invCities.push(parseInt(resultData.result.data.json.list[j].token_id));
                                                                }

                                                                if (myNftObjList.length >= maxTotal) {
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });

                                                countRes = countRes + 1;
                                            }
                                        }
                                    }

                                    this.myNftObjListData = myNftObjList;
                                });
                            } else {
                                await metaMaskTools.erc721BasicPublicCall(obj.address, "balanceOf", [this.$store.state.accounts[0]]).then(async (balance) => {
                                    this.myNftItems = parseInt(balance);
                                });

                                await metaMaskTools.getMyNft(obj.address, [page]).then(async (res) => {
                                    this.invCities = [];
                                    let myNftObjList = [];
                                    for (let i = 0; i < res[0].length; i++) {
                                        if (parseInt(res[0][i]) > 0) {
                                            myNftObjList.push({
                                                name: obj.name,
                                                img: obj.img,
                                                address: obj.address,
                                                nftId: res[0][i],
                                                nftType: res[1][i],
                                                nftUri: res[2][i],
                                            });

                                            this.invCities.push(parseInt(res[0][i]));
                                        } else {
                                            break;
                                        }
                                    }

                                    this.myNftObjListData = myNftObjList;
                                });
                            }
                        } catch (err) {
                            console.log(err);
                        }
                        this.isMyNftObjListLoading = false;
                    } else if (this.allCollectionsType === 2) {
                        setTimeout(() => {
                            this.setBitseaPageHeight();
                        }, 10);

                        await this.getNftTransaction(this.nftTransactionPageNum);
                    } else if (this.allCollectionsType === 3) {
                        this.pageSize = 100;
                        this.isMyNftObjListLoading = true;
                        try {
                            // await metaMaskTools.getShopBalanceOf([this.$store.state.accounts[0]]).then(async (balance) => {
                            //     this.myNftItems = parseInt(balance);
                            // });

                            await this.$http.listgoods(page, 10, '', obj.address, this.$store.state.accounts[0]).then(async (res) => {
                                if (res.count) {
                                    this.myNftItems = parseInt(res.count);
                                } else {
                                    this.myNftItems = 0;
                                }
                                if (res.data) {
                                    this.invCities = [];
                                    let listedListDataArr = [];

                                    for (let i = 0; i < res.data.length; i++) {
                                        this.invCities.push(parseInt(res.data[i].CGoodsID));

                                        if (obj.address.toUpperCase() === "0X57C46671A01AAD9359D97F844916CCAB4BF6713C") {
                                            res.data[i].CUri = obj.img;
                                        }

                                        if (res.data[i].COwner === "") {
                                            let obj = {
                                                goodsId: res.data[i].CGoodsID,
                                                name: res.data[i].CName,
                                                address: res.data[i].CAddress,
                                                owner: "0x0000000000000000000000000000000000000000",
                                                nftId: res.data[i].CTokenID,
                                                img: res.data[i].CUri,
                                                nftType: res.data[i].CType,
                                                timestamp: res.data[i].CTimestamp,
                                                nftPrice: res.data[i].CPrice,
                                            };
                                            await listedListDataArr.push(obj);

                                            await metaMaskTools.getGoodsInfo([res.data[i].CGoodsID]).then(async (res) => {
                                                if (res) {
                                                    listedListDataArr[i].owner = res[1];
                                                }
                                            });
                                        } else {
                                            let obj = {
                                                goodsId: res.data[i].CGoodsID,
                                                name: res.data[i].CName,
                                                address: res.data[i].CAddress,
                                                owner: res.data[i].COwner,
                                                nftId: res.data[i].CTokenID,
                                                img: res.data[i].CUri,
                                                nftType: res.data[i].CType,
                                                timestamp: res.data[i].CTimestamp,
                                                nftPrice: res.data[i].CPrice,
                                            };
                                            await listedListDataArr.push(obj);
                                        }
                                    }

                                    this.myNftObjListData = listedListDataArr;
                                }
                            });
                        } catch (err) {
                            console.log(err);
                        }
                        this.isMyNftObjListLoading = false;
                    }
                }

                setTimeout(async () => {
                    await this.setBitseaPageHeight();
                }, 100);
            },
            onTransfer(obj) {
                this.transferObj.name = obj.name;
                this.transferObj.img = obj.img;
                this.transferObj.address = obj.address;
                this.transferObj.nftId = obj.nftId;
                this.transferObj.nftUri = obj.nftUri;

                this.transferDialog = true;
            },
            closeTransferDialog() {
                this.transferDialog = false;

                this.transferObj = {
                    name: null,
                    img: null,
                    address: null,
                    nftId: 0,
                    nftUri: null,
                };
                this.receiveAddress = null;
            },
            async toTransfer(obj) {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        if (this.receiveAddress === null || this.receiveAddress === '' || this.receiveAddress === '0x0000000000000000000000000000000000000000') {
                            this.$message.warning('Please enter the receiving address!');
                            return;
                        }
                        await metaMaskTools.erc721BasicPublicSend(obj.address, "safeTransferFrom", [this.$store.state.accounts[0], this.receiveAddress, obj.nftId]).then(async (res) => {
                            await this.closeTransferDialog();
                            await this.getMyNftListData(this.selectNftObj, this.selectNftIndex, this.pageNum);
                        });
                    } else {
                        this.$message.warning('Please connect the wallet!');
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            closeListItem() {
                this.listItemObj = {
                    name: null,
                    img: null,
                    address: null,
                    nftId: 0,
                    nftUri: null,
                    nftType: 0,
                    floorPrice: 0,
                    listingPrice: 0,
                    makerFee: 0,
                    yourEarnings: 0,
                    isApprovedForAll: false,
                }
                this.priceAmount = null;

                this.isOnBatchListing = false;
                this.profileListTimeDialog = false;
            },
            async onList(obj) {
                this.listItemObj.name = obj.name;
                this.listItemObj.img = obj.img;
                this.listItemObj.address = obj.address;
                this.listItemObj.nftId = obj.nftId;
                this.listItemObj.nftUri = obj.nftUri;
                this.listItemObj.nftType = obj.nftType;
                this.listItemObj.floorPrice = 0;
                this.listItemObj.listingPrice = 0;
                this.listItemObj.makerFee = 0;
                this.listItemObj.yourEarnings = 0;

                this.profileListTimeDialog = true;

                if (obj.name === "MonsterLand404") {
                    await metaMaskTools.getIsApprovedForAll([this.$store.state.accounts[0], shopAddress]).then((res) => {
                        this.listItemObj.isApprovedForAll = res;
                    });
                } else {
                    await metaMaskTools.erc721BasicPublicCall(obj.address, "isApprovedForAll", [this.$store.state.accounts[0], shopAddress]).then((res) => {
                        this.listItemObj.isApprovedForAll = res;
                    });
                }

                await metaMaskTools.getPtransfer().then(async (makerFee) => {
                    this.listItemObj.makerFee = parseFloat(parseInt(makerFee) / 10);
                });
                await metaMaskTools.getListingPrice([obj.address, obj.nftId]).then(async (listingPrice) => {
                    this.listItemObj.listingPrice = parseInt(listingPrice);
                });
                await this.$http.listdeal(1, 10, this.selectNftObj.address).then(async (res) => {
                    if (res.FLOOR_RICE) {
                        this.listItemObj.floorPrice = parseInt(res.FLOOR_RICE);
                    }
                });
            },
            setYourEarnings(num) {
                if (this.listItemObj.makerFee && parseFloat(this.listItemObj.makerFee) > 0) {
                    this.listItemObj.yourEarnings = ((100 - parseFloat(this.listItemObj.makerFee)) / 100) * parseFloat(num);
                }
            },
            async toSetApprovalForAll(obj) {
                if (this.$store.state.accounts.length > 0) {
                    try {
                        if (obj.name === "MonsterLand404") {
                            await metaMaskTools.getIsApprovedForAll([this.$store.state.accounts[0], shopAddress]).then(async (res) => {
                                this.listItemObj.isApprovedForAll = res;

                                console.log(res);

                                if (res) {
                                    await this.toCompleteListing(obj);
                                } else {
                                    await metaMaskTools.toSetApprovalForAll([shopAddress, true]).then(async (res) => {
                                        await this.onList(obj);

                                        await this.toCompleteListing(obj);
                                    });
                                }
                            });
                        } else {
                            await metaMaskTools.erc721BasicPublicCall(obj.address, "isApprovedForAll", [this.$store.state.accounts[0], shopAddress]).then(async (res) => {
                                this.listItemObj.isApprovedForAll = res;

                                if (res) {
                                    await this.toCompleteListing(obj);
                                } else {
                                    await metaMaskTools.erc721BasicPublicSend(obj.address, "setApprovalForAll", [shopAddress, true]).then(async (res) => {
                                        await this.onList(obj);

                                        await this.toCompleteListing(obj);
                                    });
                                }
                            });
                        }
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.$message.warning('Please connect the wallet!');
                }
            },
            async toCompleteListing(obj) {
                // this.$message.warning('Coming soon!');
                // return;

                if (this.$store.state.accounts.length > 0) {
                    if (!this.priceAmount || parseFloat(this.priceAmount) <= 0) {
                        this.$message.warning('Please enter the price!');
                        return;
                    }
                    try {
                        // let price = parseInt(parseFloat(this.priceAmount) * 1e18);
                        let price = BigNumberMul(parseFloat(this.priceAmount), 1e18);

                        await metaMaskTools.onSell([obj.address, obj.nftId, price, obj.name]).then(async (res) => {
                            await this.closeListItem();
                            await this.initCheckbox();

                            await this.$http.upchainok().then((res) => {
                                console.log(res);
                            });

                            await this.getMyNftListData(this.selectNftObj, this.selectNftIndex, this.pageNum);
                            await this.getBalance();
                        });
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    this.$message.warning('Please connect the wallet!');
                }
            },
            async offSell(goodsId) {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        await metaMaskTools.offSell([goodsId]).then(async (res) => {
                            await this.initCheckbox();

                            await this.$http.upchainok().then((res) => {
                                console.log(res);
                            });

                            await this.getMyNftListData(this.selectNftObj, this.selectNftIndex, this.pageNum);
                        });
                    } else {
                        this.$message.warning('Please connect the wallet!');
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            invHandleCheckAllChange(val) {
                this.invCheckedCities = val ? this.invCities : [];
                this.invIsIndeterminate = false;
            },
            invHandleCheckedCitiesChange(value) {
                let checkedCount = value.length;
                this.invCheckAll = checkedCount === this.invCities.length;
                this.invIsIndeterminate = checkedCount > 0 && checkedCount < this.invCities.length;
            },
            async onBatchListing(listSp) {
                if (this.$store.state.accounts.length > 0) {
                    if (this.invCheckedCities.length > 0 && listSp.length > 0) {
                        this.isOnBatchListing = true;

                        for (let i = 0; i < listSp.length; i++) {
                            if (parseInt(this.invCheckedCities[0]) === parseInt(listSp[i].nftId)) {
                                this.listItemObj.name = listSp[i].name;
                                this.listItemObj.img = listSp[i].img;
                                this.listItemObj.address = listSp[i].address;
                                this.listItemObj.nftId = listSp[i].nftId;
                                this.listItemObj.nftUri = listSp[i].nftUri;
                                this.listItemObj.nftType = listSp[i].nftType;

                                break;
                            }
                        }

                        this.listItemObj.floorPrice = 0;
                        this.listItemObj.listingPrice = 0;
                        this.listItemObj.makerFee = 0;
                        this.listItemObj.yourEarnings = 0;

                        this.profileListTimeDialog = true;

                        if (listSp[0].name === "MonsterLand404") {
                            await metaMaskTools.getIsApprovedForAll([this.$store.state.accounts[0], shopAddress]).then((res) => {
                                this.listItemObj.isApprovedForAll = res;
                            });
                        } else {
                            await metaMaskTools.erc721BasicPublicCall(listSp[0].address, "isApprovedForAll", [this.$store.state.accounts[0], shopAddress]).then((res) => {
                                this.listItemObj.isApprovedForAll = res;
                            });
                        }

                        await metaMaskTools.getPtransfer().then(async (makerFee) => {
                            this.listItemObj.makerFee = parseFloat(parseInt(makerFee) / 10);
                        });
                        await metaMaskTools.getListingPrice([listSp[0].address, listSp[0].nftId]).then(async (listingPrice) => {
                            this.listItemObj.listingPrice = parseInt(listingPrice);
                        });
                        await this.$http.listdeal(1, 10, this.selectNftObj.address).then(async (res) => {
                            if (res.FLOOR_RICE) {
                                this.listItemObj.floorPrice = parseInt(res.FLOOR_RICE);
                            }
                        });
                    } else {
                        this.$message.warning('Please select your product!');
                    }
                } else {
                    this.$message.warning('Please connect the wallet!');
                }
            },
            async toBatchListing(obj) {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        if (this.invCheckedCities.length > 0) {

                            if (obj.name === "MonsterLand404") {
                                await metaMaskTools.getIsApprovedForAll([this.$store.state.accounts[0], shopAddress]).then(async (res) => {
                                    this.listItemObj.isApprovedForAll = res;

                                    console.log(res);

                                    if (res) {
                                        await this.toCompleteBatchListing(obj);
                                    } else {
                                        await metaMaskTools.toSetApprovalForAll([shopAddress, true]).then(async (res) => {
                                            let list = [];
                                            list.push(obj);
                                            await this.onBatchListing(list);

                                            await this.toCompleteBatchListing(obj);
                                        });
                                    }
                                });
                            } else {
                                await metaMaskTools.erc721BasicPublicCall(obj.address, "isApprovedForAll", [this.$store.state.accounts[0], shopAddress]).then(async (res) => {
                                    this.listItemObj.isApprovedForAll = res;

                                    if (res) {
                                        await this.toCompleteBatchListing(obj);
                                    } else {
                                        await metaMaskTools.erc721BasicPublicSend(obj.address, "setApprovalForAll", [shopAddress, true]).then(async (res) => {
                                            let list = [];
                                            list.push(obj);
                                            await this.onBatchListing(list);

                                            await this.toCompleteBatchListing(obj);
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message.warning('Please select your product!');
                        }
                    } else {
                        this.$message.warning('Please connect the wallet!');
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            async toCompleteBatchListing(obj) {
                if (this.$store.state.accounts.length > 0) {
                    if (this.invCheckedCities.length > 0) {
                        if (this.invCheckedCities.length > 50) {
                            this.$message.warning('The quantity of a batch cannot exceed 50!');
                            return;
                        }
                        if (!this.priceAmount || parseFloat(this.priceAmount) <= 0) {
                            this.$message.warning('Please enter the price!');
                            return;
                        }

                        try {
                            let price = BigNumberMul(parseFloat(this.priceAmount), 1e18);

                            await metaMaskTools.allOnSell([obj.address, this.invCheckedCities, price, obj.name]).then(async (res) => {
                                await this.closeListItem();
                                await this.initCheckbox();

                                await this.$http.upchainok().then((res) => {
                                    console.log(res);
                                });

                                await this.getMyNftListData(this.selectNftObj, this.selectNftIndex, 1);
                                await this.getBalance();
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        this.$message.warning('Please select your product!');
                    }
                } else {
                    this.$message.warning('Please connect the wallet!');
                }
            },
            async toBatchDelisting() {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        if (this.invCheckedCities.length > 0) {
                            if (this.invCheckedCities.length > 50) {
                                this.$message.warning('The quantity of a batch cannot exceed 50!');
                                return;
                            }

                            await metaMaskTools.allOffSell([this.invCheckedCities]).then(async (res) => {
                                await this.initCheckbox();

                                await this.$http.upchainok().then((res) => {
                                    console.log(res);
                                });

                                await this.getMyNftListData(this.selectNftObj, this.selectNftIndex, 1);
                            });
                        } else {
                            this.$message.warning('Please select an order!');
                        }
                    } else {
                        this.$message.warning('Please connect the wallet!');
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            initCheckbox() {
                this.invCheckAll = false;
                this.invIsIndeterminate = false;
                this.invCheckedCities = [];
            }
        }
    }
</script>

<style lang="less">
    .bitsea_my_profile_page {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        .bitsea_bj_img {
            position: absolute;
            width: 100%;
            height: calc(100% - 84px);
            top: 84px;
            bottom: 0;
            left: 0;
        }
        .bitsea_my_profile_page_01 {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .bitsea_my_profile_page_02::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .bitsea_my_profile_page_02 {
            overflow-y: auto;
            position: absolute;
            width: 100%;
            top: 84px;
            bottom: 0;
            .bitsea_my_profile_page_02_divs {
                position: relative;
                width: 80%;
                top: 0;
                bottom: 0;
                left: 10%;
                .bitsea_my_profile_page_021 {
                    width: max-content;
                    min-width: 330px;
                    padding: 12px 16px;
                    color: white;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    border: 1px solid #525151;
                    border-radius: 10px;
                    margin-top: 100px;
                    .bitsea_my_profile_page_0211 {
                        margin: 10px;
                    }
                    .bitsea_my_profile_page_0212 {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-right: 45px;
                        margin-left: 10px;
                        .bitsea_my_profile_page_02121 {
                            font-size: 24px;
                            // font-family: Poppins, Poppins;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 28px;
                            -webkit-background-clip: text;
                        }
                        .bitsea_my_profile_page_02122 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            margin: 10px 0;
                            .bitsea_my_profile_page_021221 {
                                font-size: 14px;
                                // font-family: Poppins, Poppins;
                                font-weight: 400;
                                color: rgba(255, 255, 255, 0.5);
                                line-height: 16px;
                                -webkit-background-clip: text;
                                margin-right: 8px;
                            }
                            img {
                                cursor: pointer;
                            }
                        }
                        .bitsea_my_profile_page_02123 {
                            height: 14px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            img {
                                margin-right: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                    .bitsea_my_profile_page_0213 {
                        .bitsea_my_profile_page_02131 {
                            font-size: 14px;
                            // font-family: Poppins, Poppins;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 16px;
                            -webkit-background-clip: text;
                        }
                        .bitsea_my_profile_page_02132 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 8px;
                            .bitsea_my_profile_page_021321 {
                                font-size: 14px;
                                // font-family: Poppins, Poppins;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 16px;
                                -webkit-background-clip: text;
                                margin-right: 10px;
                            }
                            img {
                                width: 24px;
                                margin-right: 5px;
                                margin-left: -5px;
                            }
                        }
                    }
                }
                .bitsea_my_profile_page_022_all_00 {
                    width: 58%;
                    height: 32px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    align-items: center;

                    margin-top: 30px;
                    border-radius: 20px;
                    opacity: 0.9;
                    border: 1px solid #3A3A3C;
                    background-color: #f7931a;
                    background-image: linear-gradient(#f7931a, #f7931a);
                    img {
                        width: 14px;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    div {
                        font-size: 15px;
                        font-family: Arial;
                        font-weight: bold;
                        color: white;
                        margin-left: 10px;
                    }
                }
                .bitsea_my_profile_page_022 {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    .bitsea_my_profile_page_0221 {
                        font-size: 32px;
                        // font-family: Poppins, Poppins;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 38px;
                        -webkit-background-clip: text;

                        @media (max-width: 1023px){
                            display: none;
                        }
                    }
                    .bitsea_my_profile_page_0222 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-end;
                        align-items: center;
                        .bitsea_my_profile_page_0222_select {
                            background: #f7931a !important;
                            border: 1px solid #f7931a !important;
                        }
                        .bitsea_my_profile_page_02221:hover {
                            background: #f7931a;
                            border: 1px solid #f7931a;
                        }
                        .bitsea_my_profile_page_02221 {
                            color: #FFFFFF;
                            width: 115px;
                            height: 40px;
                            line-height: 40px;
                            border: 1px solid #5d5d5d;
                            border-radius: 20px;
                            text-align: center;
                            cursor: pointer;
                            margin-right: 10px;
                        }
                        .bitsea_my_profile_page_02222:hover {
                            background: #f7931a;
                            border: 1px solid #f7931a;
                        }
                        .bitsea_my_profile_page_02222 {
                            color: #FFFFFF;
                            width: 115px;
                            height: 40px;
                            line-height: 40px;
                            border: 1px solid #5d5d5d;
                            border-radius: 20px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
                .bitsea_my_profile_page_023 {
                    .bitsea_my_profile_page_023a {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                        .bitsea_my_profile_page_023a1 {
                            width: 272px;
                            height: 660px;
                            background: #1c1c1ca6;
                            border-radius: 10px;
                            border: 1px solid #4b4b4b;
                            margin: 20px 20px 70px 0;

                            @media (max-width: 1023px){
                                display: none;
                            }
                            .bitsea_my_profile_page_023a11 {
                                width: 245px;
                                height: 45px;
                                border: 1px solid #555555;
                                opacity: 0.97;
                                border-radius: 25px;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-items: center;
                                justify-content: flex-start;
                                margin: 20px 12px;
                                background: #343434;
                                input {
                                    width: 199px;
                                    height: 43px;
                                    font-size: 15px;
                                    // font-family: ArialRoundedMT;
                                    font-weight: bold;
                                    color: #a1a1a1;
                                    line-height: 48px;
                                    border-radius: 2px;
                                    border: 0px solid rgba(143,143,143,0);
                                    background: rgba(255, 255, 255, 0);
                                    margin-left: 15px;
                                    margin-bottom: 1px;
                                }
                                input::placeholder {
                                    color: #a1a1a1;
                                }
                                img {
                                    margin-left: 10px;
                                    cursor: pointer;
                                }
                            }
                            .bitsea_my_profile_page_023a12 {
                                .bitsea_my_profile_page_023a121 {
                                    margin: 0 12px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    align-items: center;
                                    color: rgba(255,255,255,0.6);
                                    font-size: 12px;
                                }
                                .bitsea_my_profile_page_023a122::-webkit-scrollbar {
                                    width: 0;
                                    height: 0;
                                }
                                .bitsea_my_profile_page_023a122 {
                                    overflow-y: auto;
                                    height: 530px;
                                    margin: 10px 12px;
                                    .bitsea_my_profile_page_023a1221:hover {
                                        background: rgba(17, 17, 17, 0.35);
                                        border-radius: 5px;
                                    }
                                    .bitsea_my_profile_page_023a1221 {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        align-items: center;
                                        color: white;
                                        margin: 5px 0;
                                        cursor: pointer;
                                        .bitsea_my_profile_page_023a12211 {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            justify-content: flex-start;
                                            align-items: center;
                                            img {
                                                width: 48px;
                                            }
                                            .bitsea_my_profile_page_023a122111 {
                                                margin-left: 10px;
                                                .bitsea_my_profile_page_023a1221111 {
                                                    font-size: 14px;
                                                    // font-family: Poppins, Poppins;
                                                    font-weight: bold;
                                                    color: #FFFFFF;
                                                    line-height: 14px;
                                                    -webkit-background-clip: text;
                                                }
                                                .bitsea_my_profile_page_023a1221112 {
                                                    font-size: 12px;
                                                    // font-family: Poppins, Poppins;
                                                    font-weight: 400;
                                                    color: rgba(255,255,255,0.8);
                                                    line-height: 12px;
                                                    -webkit-background-clip: text;
                                                    margin-top: 6px;
                                                }
                                            }
                                        }
                                        .bitsea_my_profile_page_023a12212 {
                                            .bitsea_my_profile_page_023a122121 {
                                                font-size: 13px;
                                                // font-family: Poppins, Poppins;
                                                font-weight: 600;
                                                color: #FFFFFF;
                                                line-height: 14px;
                                                -webkit-background-clip: text;
                                            }
                                            .bitsea_my_profile_page_023a122122 {
                                                font-size: 12px;
                                                // font-family: Poppins, Poppins;
                                                font-weight: 500;
                                                color: #84ED73;
                                                line-height: 12px;
                                                -webkit-background-clip: text;
                                                margin-top: 6px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .bitsea_my_profile_page_023a2 {
                            width: 100%;
                            .bitsea_my_profile_page_batch_listing_all {
                                margin-top: 15px;
                                width: calc(100% - 290px);
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                                align-items: center;

                                @media (max-width: 1023px){
                                    width: 100%;
                                    justify-content: flex-start;
                                }
                                .bitsea_my_profile_page_batch_listing_011 {
                                    color: #FFFFFF;
                                    width: 115px;
                                    height: 40px;
                                    line-height: 40px;
                                    border-radius: 20px;
                                    text-align: center;
                                    cursor: pointer;
                                    margin-right: 10px;
                                    background: #f7931a !important;
                                    border: 1px solid #f7931a !important;
                                }
                                .bitsea_my_profile_page_batch_listing_012 {
                                    color: #FFFFFF;
                                    width: 115px;
                                    height: 40px;
                                    line-height: 40px;
                                    border-radius: 20px;
                                    text-align: center;
                                    cursor: pointer;
                                    margin-left: 10px;
                                    background: #f7931a !important;
                                    border: 1px solid #f7931a !important;
                                }
                            }
                            .bitsea_my_profile_page_023a21 {
                                font-size: 20px;
                                // font-family: Poppins, Poppins;
                                font-weight: 600;
                                color: rgba(255, 255, 255, 0.5);
                                line-height: 23px;
                                -webkit-background-clip: text;
                                margin-top: 20px;
                                margin-left: 15px;
                            }
                            .bitsea_my_profile_page_023a22 {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: center;
                                width: calc(100% - 290px);
                                margin-bottom: 30px;

                                @media (max-width: 1023px){
                                    width: 100%;
                                    zoom: 0.85;
                                }
                                .bitsea_my_profile_page_023a221 {
                                    position: relative;
                                    width: 205px;
                                    height: 300px;
                                    margin: 10px 15px;
                                    /*background: rgba(10, 10, 10, 0.4);*/
                                    border: 1px solid #525151;
                                    border-radius: 10px;
                                    /*box-shadow: 0 0 10px 10px #2c2c2ca1;*/
                                    .bitsea_my_profile_page_023a2211_bj {
                                        width: 205px;
                                        height: 300px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;

                                        -webkit-filter: blur(25px);
                                        filter: blur(25px);
                                    }
                                    .bitsea_my_profile_page_023a2211 {
                                        position: absolute;
                                        width: 190px;
                                        height: 190px;
                                        margin: 6px;
                                        border: 1px solid #525151;
                                        border-radius: 10px;
                                    }
                                    .bitsea_my_profile_page_023a2211_checkbox {
                                        position: relative;
                                        left: 15px;
                                        top: 10px;
                                    }
                                    .bitsea_my_profile_page_023a2212 {
                                        position: absolute;
                                        top: 205px;
                                        margin: 3px 8px;
                                        .bitsea_my_profile_page_023a22121 {
                                            font-size: 14px;
                                            // font-family: Poppins, Poppins;
                                            font-weight: bold;
                                            color: #FFFFFF;
                                            line-height: 14px;
                                            -webkit-background-clip: text;
                                        }
                                        .bitsea_my_profile_page_023a22122 {
                                            width: 190px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            font-size: 12px;
                                            // font-family: Poppins, Poppins;
                                            font-weight: 500;
                                            color: #b6b6b6;
                                            line-height: 14px;
                                            -webkit-background-clip: text;
                                            margin-top: 5px;
                                        }
                                    }
                                    .bitsea_my_profile_page_023a2213_alls {
                                        position: absolute;
                                        top: 242px;
                                        width: calc(100% - 30px);
                                        height: 30px;
                                        margin: 12px 15px;

                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        align-items: center;
                                        .bitsea_my_profile_page_023a2213 {
                                            /*position: absolute;*/
                                            /*top: 242px;*/
                                            width: calc(100% - 35px);
                                            height: 30px;
                                            line-height: 30px;
                                            color: white;
                                            font-size: 13px;
                                            // font-family: Inter, Inter;
                                            font-weight: bold;
                                            text-align: center;
                                            background-color: #f8af55;
                                            background-image: linear-gradient(to right, #f8af55, #F7931A);
                                            border-radius: 16px;
                                            cursor: pointer;
                                            /*margin: 12px 15px;*/
                                        }
                                        .bitsea_my_profile_page_023a2213_a {
                                            display: flex;
                                            flex-direction: column;
                                            flex-wrap: nowrap;
                                            justify-content: flex-start;
                                            align-items: center;
                                            width: 30px;
                                            height: 30px;
                                            color: white;
                                            font-size: 12px;
                                            font-weight: 600;
                                            cursor: pointer;
                                            img {
                                                width: 27px;
                                                height: 33px;
                                            }
                                        }
                                    }
                                    .bitsea_my_profile_page_023a2213_c {
                                        position: absolute;
                                        top: 242px;
                                        width: calc(100% - 35px);
                                        height: 30px;
                                        line-height: 30px;
                                        color: white;
                                        font-size: 13px;
                                        font-family: Inter, Inter;
                                        font-weight: bold;
                                        text-align: center;
                                        background-color: #f8af55;
                                        background-image: linear-gradient(to right, #f8af55, #F7931A);
                                        border-radius: 16px;
                                        cursor: pointer;
                                        margin: 12px 17px;
                                    }
                                }
                                .bitsea_my_profile_page_023a222 {
                                    width: 100%;
                                    margin-top: 100px;
                                    color: white;
                                    text-align: center;
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }
                            .bitsea_my_profile_page_023a23 {
                                width: calc(100% - 290px);
                                height: 35px;
                                text-align: center;
                                margin-bottom: 70px;
                            }
                        }
                    }
                    .bitsea_my_profile_page_023b {
                        width: 100%;
                        border-radius: 15px;
                        background: rgba(0, 0, 0, 0.2);
                        margin-top: 25px;
                        margin-bottom: 30px;

                        @media (max-width: 1023px){
                            overflow-x: auto;
                        }
                        .bitsea_my_profile_page_023b1 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: flex-end;
                            color: #e3e2e2;
                            margin: 0 30px;
                            height: 60px;
                            line-height: 2;
                            border-bottom: 1px solid #363636;
                            .bitsea_my_profile_page_023b11 {
                                width: 105px;
                                margin-bottom: 10px;
                            }
                            .bitsea_my_profile_page_023b12 {
                                width: 50px;
                                margin-bottom: 10px;
                            }
                            .bitsea_my_profile_page_023b13 {
                                width: 150px;
                                margin-bottom: 10px;
                            }
                            .bitsea_my_profile_page_023b14 {
                                width: 80px;
                                margin-bottom: 10px;
                            }
                            .bitsea_my_profile_page_023b15 {
                                width: 110px;
                                margin-bottom: 10px;
                            }
                            .bitsea_my_profile_page_023b16 {
                                width: 110px;
                                margin-bottom: 10px;
                            }
                            .bitsea_my_profile_page_023b17 {
                                width: 125px;
                                margin-bottom: 10px;
                            }
                        }
                        .bitsea_my_profile_page_023b2 {
                            width: 100%;
                            .bitsea_my_profile_page_023b21 {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: center;
                                color: white;
                                height: 70px;
                                margin: 10px 30px;
                                border-bottom: 1px solid #363636;
                                .bitsea_my_profile_page_023b211 {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    align-items: center;
                                    .bitsea_my_profile_page_023b2111 {
                                        margin-left: 20px;
                                    }
                                    img {
                                        margin: 5px;
                                    }
                                    width: 105px;
                                }
                                .bitsea_my_profile_page_023b212 {
                                    width: 50px;
                                    img {
                                        width: 49px;
                                    }
                                }
                                .bitsea_my_profile_page_023b213 {
                                    width: 150px;
                                }
                                .bitsea_my_profile_page_023b214 {
                                    width: 80px;
                                }
                                .bitsea_my_profile_page_023b215 {
                                    width: 110px;
                                }
                                .bitsea_my_profile_page_023b216 {
                                    width: 110px;
                                }
                                .bitsea_my_profile_page_023b217 {
                                    width: 125px;
                                }
                            }
                            .bitsea_my_profile_page_023b22 {
                                width: 100%;
                                line-height: 200px;
                                color: white;
                                text-align: center;
                                font-size: 24px;
                                font-weight: 600;
                            }
                        }
                    }
                    .bitsea_my_profile_page_023b3 {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 70px;
                    }
                }
            }
            .bitsea_my_profile_page_03 {
                width: 100%;
            }
        }

        .my_profile_list_time_dialog_modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            margin: 0;
            background: rgba(19,19,19,0.5);
            /*border-radius: 16px 16px 16px 16px;*/
            /*border: 1px solid #F7931A;*/
            z-index: 2001;

            @media (max-width: 1023px){
                zoom: 0.8;
            }
            .my_profile_list_time_dialog_div {
                position: relative;
                margin: 0 auto 50px;
                margin-top: 25vh;
                /*margin-left: calc((100% - 500px) / 2);*/

                width: 500px;
                height: 520px;
                background: #494949;
                box-shadow: 2px 2px 0px 0px #F7931A;
                border-radius: 16px;
                .my_profile_list_time_dialog_div_01 {
                    width: 500px;
                    height: 70px;
                    background: #494949;
                    border-radius: 16px;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    div {
                        font-size: 26px;
                        // font-family: Arial, Arial;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 35px;
                        -webkit-background-clip: text;
                        margin-left: 15px;
                    }
                    img {
                        width: 30px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
                .my_profile_list_time_dialog_div_02 {
                    margin: 0 13px;
                    .my_profile_list_time_dialog_div_021 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #646464;
                        height: 142px;
                        .my_profile_list_time_dialog_div_0211 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            img {
                                width: 120px;
                                height: 120px;
                                margin-right: 15px;
                                border-radius: 10px;
                            }
                            .my_profile_list_time_dialog_div_02111 {
                                .my_profile_list_time_dialog_div_021111 {
                                    width: 335px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 16px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                    line-height: 19px;
                                    -webkit-background-clip: text;
                                }
                                .my_profile_list_time_dialog_div_021112 {
                                    width: 215px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 14px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: 14px;
                                    -webkit-background-clip: text;
                                    margin-top: 10px;
                                }
                            }
                        }
                        .my_profile_list_time_dialog_div_0212 {
                            .my_profile_list_time_dialog_div_02121 {
                                font-size: 12px;
                                // font-family: Poppins, Poppins;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 14px;
                                -webkit-background-clip: text;
                            }
                            .my_profile_list_time_dialog_div_02122 {
                                font-size: 16px;
                                // font-family: Poppins, Poppins;
                                font-weight: bold;
                                color: #FFFFFF;
                                line-height: 19px;
                                -webkit-background-clip: text;
                                margin-top: 10px;
                            }
                        }
                    }
                    .my_profile_list_time_dialog_div_022 {
                        border-bottom: 1px solid #7a7a7a;
                        .my_profile_list_time_dialog_div_0221 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: flex-start;
                            .my_profile_list_time_dialog_div_02211 {
                                .my_profile_list_time_dialog_div_022111 {
                                    width: max-content;
                                    height: 45px;
                                    background: #FA951D;
                                    border-radius: 10px;
                                    text-align: center;
                                    line-height: 45px;
                                    font-size: 16px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                    margin: 30px 0;
                                }
                                .my_profile_list_time_dialog_div_022112 {
                                    font-size: 16px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: 400;
                                    color: rgba(255,255,255,0.6);
                                    line-height: 19px;
                                    -webkit-background-clip: text;
                                }
                                .my_profile_list_time_dialog_div_022113 {
                                    font-size: 20px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 23px;
                                    -webkit-background-clip: text;
                                    margin: 10px 0;
                                }
                            }
                            .my_profile_list_time_dialog_div_02212 {
                                .my_profile_list_time_dialog_div_022121 {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    border: 1px solid black;
                                    border-radius: 10px;
                                    width: 220px;
                                    margin: 30px 0;
                                    .my_profile_list_time_dialog_div_0221211 {
                                        border-right: 1px solid black;
                                        input {
                                            width: 160px;
                                            height: 43px;
                                            font-size: 15px;
                                            // font-family: ArialRoundedMT;
                                            font-weight: bold;
                                            color: #a1a1a1;
                                            line-height: 48px;
                                            border-radius: 2px;
                                            border: 0px solid rgba(143,143,143,0);
                                            background: rgba(255, 255, 255, 0);
                                            margin-left: 5px;
                                            margin-bottom: 1px;
                                        }
                                        input::placeholder {
                                            color: #a1a1a1;
                                        }
                                    }
                                    .my_profile_list_time_dialog_div_0221212 {
                                        width: 55px;
                                        img {
                                            width: 24px;
                                            margin-left: 15px;
                                        }
                                    }
                                }
                                .my_profile_list_time_dialog_div_022122 {
                                    font-size: 16px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: bold;
                                    color: rgba(255,255,255,0.6);
                                    line-height: 19px;
                                    -webkit-background-clip: text;
                                    text-align: right;
                                }
                                .my_profile_list_time_dialog_div_022123 {
                                    font-size: 20px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                    line-height: 23px;
                                    -webkit-background-clip: text;
                                    text-align: right;
                                    margin: 10px 0;
                                }
                            }
                        }
                        .my_profile_list_time_dialog_div_0222 {
                            font-size: 16px;
                            // font-family: Poppins, Poppins;
                            font-weight: 300;
                            color: rgba(255,255,255,0.3);
                            line-height: 19px;
                            -webkit-background-clip: text;
                            margin-bottom: 20px;
                        }
                        .my_profile_list_time_dialog_div_022_transfer {
                            width: 100%;
                            .input_receive_address {
                                width: 100%;
                                height: 45px;
                                font-size: 15px;
                                font-family: Inter, Inter;
                                font-weight: 400;
                                color: #5b5a5d;
                                background: black;
                                line-height: 45px;
                                border-radius: 10px;
                                margin: 10px 0 20px 0;
                            }
                        }
                    }
                    .my_profile_list_time_dialog_div_023 {
                        width: 100%;
                        height: 45px;
                        background: #FA951D;
                        font-size: 18px;
                        // font-family: Inter, Inter;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 20px;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .my_profile_page_drawer {
        height: calc(100% - 28px);
        .my_profile_page_drawer_01 {
            margin-top: 27px;
            img {
                width: 25px;
                margin-bottom: -3px;
                margin-left: 30px;
            }
        }
        .my_profile_page_drawer_02 {
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
            margin-top: 20px;
            margin-left: 12px;
        }
        .my_profile_page_drawer_alls {
            width: 272px;
            height: 660px;
            background: #1c1c1ca6;
            border-radius: 10px;
            border: 1px solid #4b4b4b;
            margin: 20px 20px 70px 0;

            @media (max-width: 1023px){
                height: calc(100% - 190px);
                border: 0px solid #4b4b4b;
            }
            .bitsea_my_profile_page_023a11 {
                width: 245px;
                height: 45px;
                border: 1px solid #555555;
                opacity: 0.97;
                border-radius: 25px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
                margin: 20px 12px;
                background: #343434;
                input {
                    width: 199px;
                    height: 43px;
                    font-size: 15px;
                    // font-family: ArialRoundedMT;
                    font-weight: bold;
                    color: #a1a1a1;
                    line-height: 48px;
                    border-radius: 2px;
                    border: 0px solid rgba(143,143,143,0);
                    background: rgba(255, 255, 255, 0);
                    margin-left: 15px;
                    margin-bottom: 1px;
                }
                input::placeholder {
                    color: #a1a1a1;
                }
                img {
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
            .bitsea_my_profile_page_023a12 {
                .bitsea_my_profile_page_023a121 {
                    margin: 0 12px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    color: rgba(255,255,255,0.6);
                    font-size: 12px;
                }
                .bitsea_my_profile_page_023a122::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                }
                .bitsea_my_profile_page_023a122 {
                    overflow-y: auto;
                    height: 610px;
                    margin: 10px 12px;
                    .bitsea_my_profile_page_023a1221:hover {
                        background: rgba(17, 17, 17, 0.35);
                        border-radius: 5px;
                    }
                    .bitsea_my_profile_page_023a1221 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        color: white;
                        margin: 5px 0;
                        cursor: pointer;
                        .bitsea_my_profile_page_023a12211 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            img {
                                width: 48px;
                            }
                            .bitsea_my_profile_page_023a122111 {
                                margin-left: 10px;
                                .bitsea_my_profile_page_023a1221111 {
                                    font-size: 14px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: bold;
                                    color: #FFFFFF;
                                    line-height: 14px;
                                    -webkit-background-clip: text;
                                }
                                .bitsea_my_profile_page_023a1221112 {
                                    font-size: 12px;
                                    // font-family: Poppins, Poppins;
                                    font-weight: 400;
                                    color: rgba(255,255,255,0.8);
                                    line-height: 12px;
                                    -webkit-background-clip: text;
                                    margin-top: 6px;
                                }
                            }
                        }
                        .bitsea_my_profile_page_023a12212 {
                            .bitsea_my_profile_page_023a122121 {
                                font-size: 13px;
                                // font-family: Poppins, Poppins;
                                font-weight: 600;
                                color: #FFFFFF;
                                line-height: 14px;
                                -webkit-background-clip: text;
                            }
                            .bitsea_my_profile_page_023a122122 {
                                font-size: 12px;
                                // font-family: Poppins, Poppins;
                                font-weight: 500;
                                color: #84ED73;
                                line-height: 12px;
                                -webkit-background-clip: text;
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    input[type="number"]{
        -moz-appearance: none !important;
    }

    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FA951D !important;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #d0770f !important;
    }
    .el-message--warning {
        z-index: 2008 !important;
    }

    .el-checkbox__input.is-focus.el-checkbox__inner {
        border-color: #d0770f !important;
    }
    .el-checkbox__inner {
        border-color: #d0770f !important;
    }
    .el-checkbox__inner:hover {
        border-color: #d0770f;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #d0770f;
        border-color: #d0770f;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #d0770f;
    }

    .el-checkbox-group {
        font-size: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center;
    }
    .el-checkbox__label {
        display: none;
    }
</style>
