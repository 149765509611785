<template>
    <div class="bitsea_menu">
        <div class="bitsea_menu_all">

            <div class="bitsea_menu_all01">
                <img src="../assets/images/Frame1116606604.png" class="bitsea_menu_01"/>
            </div>

            <div class="bitsea_menu_all03 com">
                <div class="bitsea_menu_02">
                    <div :class="menuIndex === 1 ? 'bitsea_menu_0201 bitsea_menu_02_select' : 'bitsea_menu_0201'" @click="toMenuPage(1)">Home</div>
                    <div :class="menuIndex === 2 ? 'bitsea_menu_0201 bitsea_menu_02_select' : 'bitsea_menu_0201'" @click="toMenuPage(2)">Launchpad</div>
                    <div :class="menuIndex === 3 ? 'bitsea_menu_0201 bitsea_menu_02_select' : 'bitsea_menu_0201'" @click="toMenuPage(3)">Collections</div>
                    <div :class="menuIndex === 6 ? 'bitsea_menu_0201 bitsea_menu_02_select' : 'bitsea_menu_0201'" @click="toMenuPage(6)">Swap</div>
                    <div :class="menuIndex === 4 ? 'bitsea_menu_0201 bitsea_menu_02_select' : 'bitsea_menu_0201'" @click="toMenuPage(4)">Bridge</div>
                </div>
            </div>

            <div class="bitsea_menu_all02 com">
<!--                <div class="bitsea_menu_03">-->
<!--                    <img src="../assets/images/icon_search_1.png"/>-->
<!--                    <input placeholder="Search"/>-->
<!--                </div>-->
                <div class="bitsea_menu_03_chain">
                    <div class="bitsea_menu_03_chain_01" @click="selectChain = !selectChain">
                        <div class="bitsea_menu_03_chain_011">
                            <img class="bitsea_menu_03_chain_0111" v-show="chainKey === 11501" src="../assets/images/icon_BEVM_24.png"/>
                            <img class="bitsea_menu_03_chain_0111" v-show="chainKey === 22776" src="../assets/images/icon_mapprotocol_24.png"/>
                            <img class="bitsea_menu_03_chain_0111" v-show="chainKey === 223" src="../assets/images/bTwo.png"/>

                            <div class="bitsea_menu_03_chain_0112" v-show="chainKey === 11501">BEVM</div>
                            <div class="bitsea_menu_03_chain_0112" v-show="chainKey === 22776">Map Protocol</div>
                            <div class="bitsea_menu_03_chain_0112" v-show="chainKey === 223">B² Mainnet</div>
                        </div>

                        <img class="bitsea_menu_03_chain_012" src="../assets/images/icon_arrow.png"/>
                    </div>

                    <div v-show="selectChain" class="bitsea_menu_03_chain_02">
                        <div @click="setChain(22776)" :class="chainKey === 22776 ? 'bitsea_menu_03_chain_021 bitsea_menu_03_chain_02_select' : 'bitsea_menu_03_chain_021'">
                            <div class="bitsea_menu_03_chain_0211">
                                <img src="../assets/images/icon_mapprotocol_24.png"/>
                                <div>Map Protocol</div>
                            </div>

                            <div class="bitsea_menu_03_chain_0212">
                                <img v-show="chainKey === 22776" src="../assets/images/icon_chosen.png"/>
                            </div>
                        </div>

                        <div @click="setChain(11501)" :class="chainKey === 11501 ? 'bitsea_menu_03_chain_021 bitsea_menu_03_chain_02_select' : 'bitsea_menu_03_chain_021'">
                            <div class="bitsea_menu_03_chain_0211">
                                <img src="../assets/images/icon_BEVM_24.png"/>
                                <div>BEVM</div>
                            </div>

                            <div class="bitsea_menu_03_chain_0212">
                                <img v-show="chainKey === 11501" src="../assets/images/icon_chosen.png"/>
                            </div>
                        </div>

                        <div @click="setChain(223)" :class="chainKey === 223 ? 'bitsea_menu_03_chain_021 bitsea_menu_03_chain_02_select' : 'bitsea_menu_03_chain_021'">
                            <div class="bitsea_menu_03_chain_0211">
                                <img src="../assets/images/bTwo.png"/>
                                <div>B² Mainnet</div>
                            </div>

                            <div class="bitsea_menu_03_chain_0212">
                                <img v-show="chainKey === 223" src="../assets/images/icon_chosen.png"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bitsea_menu_wallet">
                    <div class="bitsea_menu_04" :style="$store.state.accounts.length > 0 ? 'background: #f67f1000;' : ''" @click="openConnectWallet()">
                        {{$store.state.accounts.length === 0 ? 'Connect Wallet' : ''}}
                        <div class="bitsea_menu_041" v-show="$store.state.accounts.length > 0">
                            <div class="bitsea_menu_0411"></div>
                            <div class="bitsea_menu_0412">{{cutAccount($store.state.accounts[0], 5)}}</div>
                            <img class="bitsea_menu_0413" src="../assets/images/profile.png"/>
                        </div>
                    </div>
                    <div class="bitsea_menu_05" v-if="isMyProfile">
                        <div class="bitsea_menu_051" @click="toMyProfile()">My NFTs</div>
                        <div class="bitsea_menu_052" @click="onLogOut">Disconnect</div>
                    </div>
                </div>
            </div>

            <div class="bitsea_menu_all04 mob">
                <img src="../assets/images/menu.png" @click="menuMobDrawer = true"/>
            </div>

            <el-drawer
                    title="MENU"
                    size="315px"
                    :visible.sync="menuMobDrawer"
                    :with-header="false">
                <div class="bitsea_mob_menu">
                    <div class="bitsea_mob_menu_01">
                        <img src="../assets/images/close.png" @click="menuMobDrawer = false"/>
                    </div>
                    <div class="bitsea_mob_menu_02">
                        <div :class="menuIndex === 1 ? 'bitsea_mob_menu_021 bitsea_mob_menu_021_sel' : 'bitsea_mob_menu_021'" style="margin-top: 15px" @click="toMenuPage(1)">Home</div>
                        <div :class="menuIndex === 2 ? 'bitsea_mob_menu_021 bitsea_mob_menu_021_sel' : 'bitsea_mob_menu_021'" @click="toMenuPage(2)">Launchpad</div>
                        <div :class="menuIndex === 3 ? 'bitsea_mob_menu_021 bitsea_mob_menu_021_sel' : 'bitsea_mob_menu_021'" @click="toMenuPage(3)">Collections</div>
                        <div :class="menuIndex === 6 ? 'bitsea_mob_menu_021 bitsea_mob_menu_021_sel' : 'bitsea_mob_menu_021'" @click="toMenuPage(6)">Swap</div>
                        <div :class="menuIndex === 4 ? 'bitsea_mob_menu_021 bitsea_mob_menu_021_sel' : 'bitsea_mob_menu_021'" style="margin-bottom: 15px" @click="toMenuPage(4)">Bridge</div>
                    </div>

                    <div class="bitsea_mob_menu_chain">
                        <div @click="setChain(22776)" :class="chainKey === 22776 ? 'bitsea_menu_03_chain_021 bitsea_menu_03_chain_02_select' : 'bitsea_menu_03_chain_021'">
                            <div class="bitsea_menu_03_chain_0211">
                                <img src="../assets/images/icon_mapprotocol_24.png"/>
                                <div>Map Protocol</div>
                            </div>

                            <div class="bitsea_menu_03_chain_0212">
                                <img v-show="chainKey === 22776" src="../assets/images/icon_chosen.png"/>
                            </div>
                        </div>

                        <div @click="setChain(11501)" :class="chainKey === 11501 ? 'bitsea_menu_03_chain_021 bitsea_menu_03_chain_02_select' : 'bitsea_menu_03_chain_021'">
                            <div class="bitsea_menu_03_chain_0211">
                                <img src="../assets/images/icon_BEVM_24.png"/>
                                <div>BEVM</div>
                            </div>

                            <div class="bitsea_menu_03_chain_0212">
                                <img v-show="chainKey === 11501" src="../assets/images/icon_chosen.png"/>
                            </div>
                        </div>

                        <div @click="setChain(223)" :class="chainKey === 223 ? 'bitsea_menu_03_chain_021 bitsea_menu_03_chain_02_select' : 'bitsea_menu_03_chain_021'">
                            <div class="bitsea_menu_03_chain_0211">
                                <img src="../assets/images/bTwo.png"/>
                                <div>B² Mainnet</div>
                            </div>

                            <div class="bitsea_menu_03_chain_0212">
                                <img v-show="chainKey === 223" src="../assets/images/icon_chosen.png"/>
                            </div>
                        </div>
                    </div>

                    <div class="bitsea_mob_menu_03">
                        <div class="bitsea_mob_menu_031" :style="$store.state.accounts.length > 0 ? 'background: #f67f1000;' : ''" @click="openConnectWallet()">
                            <div class="bitsea_mob_menu_03111d" v-show="$store.state.accounts.length <= 0"></div>
                            {{$store.state.accounts.length === 0 ? 'Connect Wallet' : ''}}
                            <div class="bitsea_mob_menu_0311" v-show="$store.state.accounts.length > 0">
                                <div class="bitsea_mob_menu_03111"></div>
                                <div class="bitsea_mob_menu_03112">{{cutAccount($store.state.accounts[0], 5)}}</div>
                                <img class="bitsea_mob_menu_03113" src="../assets/images/profile.png"/>
                            </div>
                        </div>
                        <!-- v-if="isMyProfile" -->
                        <div class="bitsea_mob_menu_032">
                            <div class="bitsea_mob_menu_0321" @click="toMyProfile()">My NFTs</div>
                            <div class="bitsea_mob_menu_0322" @click="onLogOut">Disconnect</div>
                        </div>
                    </div>
                </div>
            </el-drawer>

        </div>

        <ConnectWallet/>
        <MetaDialog/>
    </div>
</template>

<script>
    import {cutAccount} from '../tools/index';
    import ConnectWallet from "../components/ConnectWallet";
    import MetaDialog from "../components/MetaDialog";
    import transfer from "../tools/transfer";
    import {
        debug
    } from "../config";
    import {
        ipfsUrl,
        openUrlSLD,
        bitseaNfts,
        controlAddress,
        shopAddress,
        monsterLandAddress,
        setProjectConfig
    } from "../tools/projectConfig";
    import * as metaMaskTools from "../tools/chain";

    export default {
        name: "Menu",
        components: { ConnectWallet, MetaDialog },
        data() {
            return {
                debug,
                selectChain: false,
                chainKey: 22776,
                menuIndex: 2,
                isMyProfile: false,
                menuMobDrawer: false,
                bridgeUrl: 'https://bevm.io/bridge-mainnet',
                swapUrl: 'https://swap.bitsea.ink/#/swap',
            }
        },
        watch: {},
        computed: {
        },
        created() {
            this.initChain();

            transfer.$on("toMenuPage", (page) => {
                this.toMenuPage(page);
            });

            transfer.$on("toMenuClaim", () => {
                this.toMenuClaim();
            });

            transfer.$on('closeMyProfile', () => {
                this.closeMyProfile();
            })
        },
        mounted() {
            this.initMenuPage();
            this.initChain();
        },
        destroyed() {
        },
        methods: {
            closeMyProfile() {
                this.isMyProfile = false;
            },
            toMyProfile() {
                if (this.$store.state.accounts.length > 0) {
                    this.toMenuPage(5);
                } else {
                    this.$message.warning('Please connect the wallet!');
                }
            },
            openConnectWallet() {
                if (this.$store.state.accounts.length > 0) {
                    this.isMyProfile = !this.isMyProfile;
                } else {
                    this.$store.commit('setState', {
                        connectWalletShow: true
                    })
                    this.isMyProfile = false;
                }
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            initMenuPage() {
                if (sessionStorage.getItem('menuIndex')) {
                    this.menuIndex = parseInt(sessionStorage.getItem('menuIndex'));
                } else {
                    if (this.$route.path.toString().indexOf('/home') >= 0) {
                        this.toMenuPage(1);
                    } else if (this.$route.path.toString().indexOf('/launchpad') >= 0) {
                        this.toMenuPage(2);
                    } else if (this.$route.path.toString().indexOf('/collections') >= 0) {
                        this.toMenuPage(3);
                    } else if (this.$route.path.toString().indexOf('/bridge') >= 0) {
                        this.toMenuPage(4);
                    } else if (this.$route.path.toString().indexOf('/myProfile') >= 0) {
                        this.toMenuPage(5);
                    } else {
                        this.toMenuPage(1);
                    }
                }
            },
            toMenuPage(page) {
                if (page === 1) {
                    this.$router.push("/home");
                } else if (page === 2) {
                    this.$router.push("/launchpad");
                    setTimeout(() => {
                        transfer.$emit("setMenuLaunchpad");
                    }, 10);
                } else if (page === 3) {
                    if (bitseaNfts.length > 0) {
                        this.$router.push("/collections");
                    } else {
                        this.$message.warning('Coming soon!');
                        return;
                    }
                } else if (page === 4) {
                    // this.$message.warning('Coming soon!');
                    // return;

                    if (parseInt(this.$store.state.networkIDstring) === 1502 || parseInt(this.$store.state.networkIDstring) === 11501) {
                        this.bridgeUrl = "https://bevm.io/bridge-mainnet";
                    } else if (parseInt(this.$store.state.networkIDstring) === 212 || parseInt(this.$store.state.networkIDstring) === 22776) {
                        this.bridgeUrl = "https://www.butterswap.io/swap";
                    } else if (parseInt(this.$store.state.networkIDstring) === 223) {
                        this.bridgeUrl = "https://meson.fi";
                    }

                    window.open(this.bridgeUrl, '_blank');
                } else if (page === 5) {
                    this.$router.push("/myProfile");
                    // page = 3;
                } else if (page === 6) {
                    // this.$message.warning('Coming soon!');
                    // return;

                    if (parseInt(this.$store.state.networkIDstring) === 1502 || parseInt(this.$store.state.networkIDstring) === 11501) {
                        this.swapUrl = "https://swap.bitsea.ink/#/swap";
                    } else if (parseInt(this.$store.state.networkIDstring) === 212 || parseInt(this.$store.state.networkIDstring) === 22776) {
                        this.swapUrl = "https://pro.hiveswap.io/trade/swap";
                    } else if (parseInt(this.$store.state.networkIDstring) === 223) {
                        this.swapUrl = "https://app.glowswap.io/swap";
                    }

                    window.open(this.swapUrl, '_blank');
                } else {
                    this.$message.warning('Coming soon!');
                    return;
                }

                if (page !== 4 && page !== 6) {
                    this.menuIndex = page;
                    sessionStorage.setItem('menuIndex', page);
                }
            },
            onLogOut() {
                this.$store.dispatch('logOut');
                this.toMenuPage(1);
            },
            toMenuClaim() {
                this.$router.push("/launchpad");

                setTimeout(() => {
                    transfer.$emit("onLaunchpadClaim");
                }, 10);

                this.menuIndex = 2;
                sessionStorage.setItem('menuIndex', 2);
            },
            async initChain() {
                if (window.location.href.toString().indexOf("chainId") >= 0) {
                    let chainId = this.$route.query.chainId;
                    if (
                        parseInt(chainId) === 1502 ||
                        parseInt(chainId) === 11501 ||
                        parseInt(chainId) === 212 ||
                        parseInt(chainId) === 22776 ||
                        parseInt(chainId) === 223
                    ) {
                        this.$store.commit("setState", {
                            networkIDstring: parseInt(chainId),
                        });
                    }
                }

                if (this.$store.state.networkIDstring) {
                    if (parseInt(this.$store.state.networkIDstring) === 1502 || parseInt(this.$store.state.networkIDstring) === 11501) {
                        this.chainKey = 11501;
                    } else if (parseInt(this.$store.state.networkIDstring) === 212 || parseInt(this.$store.state.networkIDstring) === 22776) {
                        this.chainKey = 22776;
                    } else if (parseInt(this.$store.state.networkIDstring) === 223) {
                        this.chainKey = 223;
                    }
                    this.$store.commit("setState", {
                        networkIDstring: parseInt(this.$store.state.networkIDstring),
                    });
                    sessionStorage.setItem('chainKey', this.chainKey);
                } else {
                    if (sessionStorage.getItem("chainKey")) {
                        this.chainKey = parseInt(sessionStorage.getItem("chainKey"));
                        if (debug) {
                            if (parseInt(this.chainKey) === 11501) {
                                this.$store.commit("setState", {
                                    networkIDstring: 1502,
                                });
                            } else if (parseInt(this.chainKey) === 22776) {
                                this.$store.commit("setState", {
                                    networkIDstring: 212,
                                });
                            } else if (parseInt(this.chainKey) === 223) {
                                this.$store.commit("setState", {
                                    networkIDstring: 223,
                                });
                            }
                        } else {
                            this.$store.commit("setState", {
                                networkIDstring: this.chainKey,
                            });
                        }
                        sessionStorage.setItem('chainKey', this.chainKey);
                    } else {
                        this.chainKey = 22776;
                        if (debug) {
                            this.$store.commit("setState", {
                                networkIDstring: 212,
                            });
                        } else {
                            this.$store.commit("setState", {
                                networkIDstring: 22776,
                            });
                        }
                        sessionStorage.setItem('chainKey', this.chainKey);
                    }
                }

                await this.setChain(this.chainKey);
            },
            async setChain(id) {
                // if (parseInt(id) === 223) {
                //     this.$message.warning('Coming soon!');
                //     return;
                // }

                this.chainKey = parseInt(id);
                sessionStorage.setItem('chainKey', this.chainKey);

                if (debug) {
                    if (parseInt(id) === 11501) {
                        this.$store.commit("setState", {
                            networkIDstring: 1502,
                        });
                    } else if (parseInt(id) === 22776) {
                        this.$store.commit("setState", {
                            networkIDstring: 212,
                        });
                    } else if (parseInt(id) === 223) {
                        this.$store.commit("setState", {
                            networkIDstring: 223,
                        });
                    }
                } else {
                    this.$store.commit("setState", {
                        networkIDstring: parseInt(id),
                    });
                }

                await setProjectConfig().then((res) => {});
                await metaMaskTools.initQueryWeb3().then((res) => {});
                await metaMaskTools.initParams().then((res) => {});

                if (window.location.href.toString().indexOf("launchpad") >= 0) {
                    transfer.$emit("launchpadInitData", bitseaNfts);
                }
                if (window.location.href.toString().indexOf("collections") >= 0) {
                    if (bitseaNfts.length > 0) {
                        transfer.$emit("collectionsInitData", bitseaNfts);
                    } else {
                        this.$message.warning('Coming soon!');
                        this.toMenuPage(1);
                    }
                }
                if (window.location.href.toString().indexOf("myProfile") >= 0) {
                    transfer.$emit("myProfileInitData", bitseaNfts);
                }

                this.selectChain = false;
            }
        }
    }
</script>

<style lang="less">
    .el-drawer {
        background: #1a191a !important;
    }

    .bitsea_menu {
        width: 100%;
        height: 84px;
        /*background-color: #ffd581;*/
        /*background-image: linear-gradient(#ffd581, #ffb168);*/
        background: #1a191a;
        .bitsea_menu_all {
            width: 80%;
            height: 84px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            margin-left: 10%;
            .bitsea_menu_all01 {
                // cursor: pointer;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .bitsea_menu_01 {
                    width: 115px;
                    height: 40px;
                    /*margin-left: 50px;*/
                }
            }
            .bitsea_menu_all02 {
                height: 45px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
                .bitsea_menu_03 {
                    width: 381px;
                    height: 45px;
                    border: 1px solid #B45602;
                    opacity: 0.97;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 30px;
                    input {
                        width: 320px;
                        height: 43px;
                        font-size: 15px;
                        // font-family: ArialRoundedMT;
                        font-weight: bold;
                        color: #CD670C;
                        line-height: 48px;
                        border-radius: 2px;
                        border: 0px solid rgba(143,143,143,0);
                        background: rgba(255, 255, 255, 0);
                        margin-left: 15px;
                        margin-bottom: 1px;
                    }
                    input::placeholder {
                        color: #CD670C;
                    }
                    img {
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
                .bitsea_menu_03_chain {
                    width: 160px;
                    height: 45px;
                    background: #242324;
                    border-radius: 8px;
                    margin-right: 10px;
                    .bitsea_menu_03_chain_01 {
                        cursor: pointer;
                        width: 160px;
                        height: 45px;

                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-between;
                        .bitsea_menu_03_chain_011 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            .bitsea_menu_03_chain_0111 {
                                width: 26px;
                                margin: 0 5px;
                            }
                            .bitsea_menu_03_chain_0112 {
                                font-family: HelveticaNeue, HelveticaNeue;
                                font-weight: bold;
                                font-size: 13px;
                                color: #FFFFFF;
                                line-height: 15px;
                                text-align: left;
                                font-style: normal;
                            }
                        }
                        .bitsea_menu_03_chain_012 {
                            margin-right: 5px;
                        }
                    }

                    .bitsea_menu_03_chain_02 {
                        position: absolute;
                        z-index: 1001;
                        margin-top: 10px;
                        width: 200px;
                        height: 180px;
                        background: linear-gradient( 270deg, #392F23 2%, #181818 100%);
                        border-radius: 8px;
                        border: 1px solid #7F4902;

                        .bitsea_menu_03_chain_02_select:hover {
                            .bitsea_menu_03_chain_0211 {
                                div {
                                    color: #FFFFFF !important;
                                }
                            }
                        }
                        .bitsea_menu_03_chain_02_select {
                            background: #f38a00;
                            border-radius: 8px;
                        }
                        .bitsea_menu_03_chain_021:hover {
                            .bitsea_menu_03_chain_0211 {
                                div {
                                    color: #F79925;
                                }
                            }
                        }
                        .bitsea_menu_03_chain_021 {
                            cursor: pointer;
                            width: 185px;
                            height: 50px;
                            margin: 6px;

                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            .bitsea_menu_03_chain_0211 {
                                margin-left: 8px;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: flex-start;
                                align-items: center;
                                div {
                                    font-family: HelveticaNeue, HelveticaNeue;
                                    font-weight: bold;
                                    font-size: 12px;
                                    color: #FFFFFF;
                                    line-height: 15px;
                                    text-align: left;
                                    font-style: normal;
                                    margin-left: 5px;
                                }
                                img {
                                    width: 26px;
                                }
                            }
                            .bitsea_menu_03_chain_0212 {
                                margin-right: 8px;
                                width: 16px;
                                height: 16px;
                                img {
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
                .bitsea_menu_wallet {
                    position: relative;
                    .bitsea_menu_04 {
                        width: 195px;
                        // height: 45px;
                        /*background: url("../assets/images/connect_btn.png");*/
                        background: #f79825;
                        border-radius: 10px;
                        text-align: center;
                        line-height: 45px;
                        font-size: 16px;
                        // font-family: ArialRoundedMT;
                        font-weight: bold;
                        color: rgba(0,0,0,.8);
                        cursor: pointer;
                        /*margin-right: 50px;*/
                        &:hover {
                            background: rgb(219, 121, 28);
                        }
                        .bitsea_menu_041 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            width: 195px;
                            .bitsea_menu_0411 {
                                width: 8px;
                                height: 8px;
                                background: #5DD35B;
                                border-radius: 0px 0px 0px 0px;
                                opacity: 1;
                                border-radius: 50%;
                            }
                            .bitsea_menu_0412 {
                                color: rgba(255,255,255,.2);

                            }
                            .bitsea_menu_0413 {
                                width: 45px;
                            }
                        }
                    }
                    .bitsea_menu_05 {
                        position: absolute;
                        z-index: 1000;
                        margin-top: 10px;
                        width: 170px;
                        height: 76px;
                        /*background: url("../assets/images/bg_down.png");*/
                        background: #f38a00;
                        // border: 1px solid #452200;
                        border-radius: 10px;
                        .bitsea_menu_051:hover {
                            color: white;
                        }
                        .bitsea_menu_052:hover {
                            color: white;
                        }
                        .bitsea_menu_051 {
                            width: 100%;
                            height: 32px;
                            line-height: 32px;
                            font-weight: 600;
                            cursor: pointer;
                            text-align: center;
                            margin-top: 6px;
                            font-size: 18px;
                            // font-family: Arial;
                            color: #000000;
                        }
                        .bitsea_menu_052 {
                            width: 100%;
                            height: 32px;
                            line-height: 32px;
                            font-weight: 600;
                            cursor: pointer;
                            text-align: center;
                            font-size: 18px;
                            // font-family: Arial;
                            color: #000000;
                        }
                    }
                }
            }
            .bitsea_menu_all03 {
                .bitsea_menu_02 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-left: 30px;
                    .bitsea_menu_0201:hover {
                        color: #e36c02;
                    }
                    .bitsea_menu_0201 {
                        width: 131px;
                        height: 40px;
                        line-height: 40px;
                        margin: 0 10px;
                        font-size: 14px;
                        // font-family: ArialRoundedMT;
                        /*font-weight: bold;*/
                        color: #727272;
                        cursor: pointer;
                        text-align: center;
                    }
                    .bitsea_menu_02_select {
                        line-height: 40px !important;
                        color: #e6e6e6 !important;
                        /*background: url("../assets/images/bg_chosen.png") !important;*/
                        border: 1px solid #727272;
                        border-radius: 10px;
                    }
                }
            }

            .bitsea_menu_all04 {
                img {
                    width: 35px;
                }
            }
            .bitsea_mob_menu {
                width: 100%;
                /*height: 100%;*/
                .bitsea_mob_menu_01 {
                    margin-top: 30px;
                    img {
                        width: 25px;
                        margin-bottom: -5px;
                        margin-left: 30px;
                    }
                }
                .bitsea_mob_menu_02 {
                    margin-top: 30px;
                    border-top: 0.5px solid white;
                    border-bottom: 0.5px solid white;
                    .bitsea_mob_menu_021_sel {
                        color: #e36c02 !important;
                    }
                    .bitsea_mob_menu_021 {
                        color: white;
                        font-weight: 600;
                        margin-left: 30px;
                        width: calc(100% - 60px);
                        height: 50px;
                        line-height: 45px;
                        font-size: 22px;
                        cursor: pointer;
                    }
                }
                .bitsea_mob_menu_03 {
                    margin-top: 20px;
                    .bitsea_mob_menu_031 {
                        margin-left: 30px;
                        color: white;
                        font-weight: 600;
                        font-size: 22px;
                        display: flex;
                        flex-flow: row;
                        justify-content: flex-start;
                        align-items: center;
                        .bitsea_mob_menu_03111d {
                            width: 8px;
                            height: 8px;
                            background: #e36c02;
                            opacity: 1;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        .bitsea_mob_menu_0311 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            flex-wrap: nowrap;
                            .bitsea_mob_menu_03111 {
                                width: 8px;
                                height: 8px;
                                background: #5DD35B;
                                opacity: 1;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                            .bitsea_mob_menu_03112 {
                                color: #e36c02 !important;
                                margin-right: 10px;
                            }
                            .bitsea_mob_menu_03113 {
                                width: 45px;
                            }
                        }
                    }
                    .bitsea_mob_menu_032 {
                        margin-left: 30px;
                        color: white;
                        font-weight: 600;
                        font-size: 20px;
                        position: absolute;
                        z-index: 1000;
                        margin-top: 15px;
                        width: 170px;
                        height: 76px;
                        background: #f38a00;
                        border-radius: 10px;

                        @media (max-width: 1023px){
                            width: 218px;
                        }

                        .bitsea_mob_menu_0321 {
                            height: 38px;
                            line-height: 38px;
                            text-align: center;
                        }
                        .bitsea_mob_menu_0322 {
                            height: 38px;
                            line-height: 38px;
                            text-align: center;
                            border-top: 0.5px solid white;
                        }
                    }
                }
                .bitsea_mob_menu_chain {
                    margin-top: 30px;
                    margin-left: 30px;
                    width: 218px;
                    height: 180px;
                    background: linear-gradient( 270deg, #392F23 2%, #181818 100%);
                    border-radius: 8px;
                    border: 1px solid #7F4902;

                    .bitsea_menu_03_chain_02_select:hover {
                        .bitsea_menu_03_chain_0211 {
                            div {
                                color: #FFFFFF !important;
                            }
                        }
                    }
                    .bitsea_menu_03_chain_02_select {
                        background: #f38a00;
                        border-radius: 8px;
                    }
                    .bitsea_menu_03_chain_021:hover {
                        .bitsea_menu_03_chain_0211 {
                            div {
                                color: #F79925;
                            }
                        }
                    }
                    .bitsea_menu_03_chain_021 {
                        cursor: pointer;
                        width: 206px;
                        height: 50px;
                        margin: 6px;

                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        .bitsea_menu_03_chain_0211 {
                            margin-left: 8px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            div {
                                font-family: HelveticaNeue, HelveticaNeue;
                                font-weight: bold;
                                font-size: 12px;
                                color: #FFFFFF;
                                line-height: 15px;
                                text-align: left;
                                font-style: normal;
                                margin-left: 5px;
                            }
                            img {
                                width: 26px;
                            }
                        }
                        .bitsea_menu_03_chain_0212 {
                            margin-right: 8px;
                            width: 16px;
                            height: 16px;
                            img {
                                width: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
