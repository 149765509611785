import Web3 from 'web3';
import store from "../store";
import Toast from "vant/lib/toast";
import 'vant/lib/toast/style';

import {
    BigNumber,
    BigNumberStr,
    BigNumberMul,
    BigNumberDiv,
    BigNumberAdd,
    BigNumberSub,
} from '../tools/index';

import { connectMetaMask, connectOKX, connectGate } from './walletConnectTools';

import abiERC20 from '../abi/abiERC20';
import abiERC721 from '../abi/abiERC721';
import abiERC404 from '../abi/abiERC404';
import abiBitseaNft from '../abi/abiBitseaNft';
import abiClaimNft from '../abi/abiClaimNft';
import abiShop from '../abi/abiShop';

import {
    debug,
} from "../config";

import {
    ipfsUrl,
    openUrlSLD,
    bitseaNfts,
    controlAddress,
    shopAddress,
    monsterLandAddress,
    setProjectConfig
} from "./projectConfig";


let queryWeb3;
const initQueryWeb3 = async () => {
    if (debug) {
        queryWeb3 = new Web3('https://testnet-rpc.maplabs.io');
        if (parseInt(store.state.networkIDstring) === 1502) {
            queryWeb3 = new Web3('https://canary-testnet.bevm.io');
        }
        if (parseInt(store.state.networkIDstring) === 223) {
            queryWeb3 = new Web3('https://rpc.bsquared.network');
        }
    } else {
        queryWeb3 = new Web3('https://rpc.maplabs.io');
        if (parseInt(store.state.networkIDstring) === 11501) {
            queryWeb3 = new Web3('https://rpc-mainnet-1.bevm.io');
        }
        if (parseInt(store.state.networkIDstring) === 223) {
            queryWeb3 = new Web3('https://rpc.bsquared.network');
        }
    }
}
// 初始化
initQueryWeb3().then(res => {});


const initWeb3hooks = async () => {
    if (sessionStorage.getItem("walletType")) {
        store.commit('setState', {
            walletType: parseInt(sessionStorage.getItem("walletType"))
        })
    }

    if (parseInt(store.state.walletType) === 0) {
        await connectOKX();
    } else if (parseInt(store.state.walletType) === 1) {
        await connectMetaMask();
    } else if (parseInt(store.state.walletType) === 2) {
        await connectGate();
    }

    await setProjectConfig();
    await initParams();
};

const initWeb3 = async () => {
    try {
        await initWeb3hooks();
    } catch (err) {
        Toast.fail(err);
    }
}

let account = localStorage.getItem('accounts');
if (account) {
    initWeb3().then(res => {});
} else {
    initQueryWeb3().then(res => {});
}

const initParams = async () => {
    if (parseInt(store.state.walletType) === 0) {
        if (window.okxwallet) {
            if (debug) {
                if (parseInt(store.state.networkIDstring) === 212) {
                    try {
                        await (window.okxwallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(212)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.okxwallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(212),
                                            chainName: 'Mapo-Test',
                                            nativeCurrency: {
                                                name: 'MAPO',
                                                symbol: 'MAPO',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://testnet-rpc.maplabs.io'],
                                            blockExplorerUrls: ['https://testnet.maposcan.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else if (parseInt(store.state.networkIDstring) === 223) {
                    try {
                        await (window.okxwallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(223)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.okxwallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(223),
                                            chainName: 'B² Mainnet',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.bsquared.network'],
                                            blockExplorerUrls: ['https://explorer.bsquared.network']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else {
                    try {
                        await (window.okxwallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(1502)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.okxwallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(1502),
                                            chainName: 'Bevm-Test',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://canary-testnet.bevm.io'],
                                            blockExplorerUrls: ['https://scan-canary-testnet.bevm.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                }
            } else {
                if (parseInt(store.state.networkIDstring) === 22776) {
                    try {
                        await (window.okxwallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(22776)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.okxwallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(22776),
                                            chainName: 'MAPO-Main',
                                            nativeCurrency: {
                                                name: 'MAPO',
                                                symbol: 'MAPO',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.maplabs.io'],
                                            blockExplorerUrls: ['https://maposcan.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else if (parseInt(store.state.networkIDstring) === 223) {
                    try {
                        await (window.okxwallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(223)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.okxwallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(223),
                                            chainName: 'B² Mainnet',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.bsquared.network'],
                                            blockExplorerUrls: ['https://explorer.bsquared.network']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else {
                    try {
                        await (window.okxwallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(11501)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.okxwallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(11501),
                                            chainName: 'BEVM-Main',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc-mainnet-1.bevm.io'],
                                            blockExplorerUrls: ['https://scan-mainnet.bevm.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                }
            }
        }
    } else if (parseInt(store.state.walletType) === 1) {
        if (window.ethereum) {
            if (debug) {
                if (parseInt(store.state.networkIDstring) === 212) {
                    try {
                        await (window.ethereum).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(212)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.ethereum).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(212),
                                            chainName: 'Mapo-Test',
                                            nativeCurrency: {
                                                name: 'MAPO',
                                                symbol: 'MAPO',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://testnet-rpc.maplabs.io'],
                                            blockExplorerUrls: ['https://testnet.maposcan.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else if (parseInt(store.state.networkIDstring) === 223) {
                    try {
                        await (window.ethereum).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(223)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.ethereum).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(223),
                                            chainName: 'B² Mainnet',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.bsquared.network'],
                                            blockExplorerUrls: ['https://explorer.bsquared.network']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else {
                    try {
                        await (window.ethereum).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(1502)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.ethereum).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(1502),
                                            chainName: 'Bevm-Test',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://canary-testnet.bevm.io'],
                                            blockExplorerUrls: ['https://scan-canary-testnet.bevm.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                }
            } else {
                if (parseInt(store.state.networkIDstring) === 22776) {
                    try {
                        await (window.ethereum).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(22776)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.ethereum).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(22776),
                                            chainName: 'MAPO-Main',
                                            nativeCurrency: {
                                                name: 'MAPO',
                                                symbol: 'MAPO',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.maplabs.io'],
                                            blockExplorerUrls: ['https://maposcan.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else if (parseInt(store.state.networkIDstring) === 223) {
                    try {
                        await (window.ethereum).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(223)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.ethereum).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(223),
                                            chainName: 'B² Mainnet',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.bsquared.network'],
                                            blockExplorerUrls: ['https://explorer.bsquared.network']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else {
                    try {
                        await (window.ethereum).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(11501)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.ethereum).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(11501),
                                            chainName: 'BEVM-Main',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc-mainnet-1.bevm.io'],
                                            blockExplorerUrls: ['https://scan-mainnet.bevm.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                }
            }
        }
    } else if (parseInt(store.state.walletType) === 2) {
        if (window.gatewallet) {
            if (debug) {
                if (parseInt(store.state.networkIDstring) === 212) {
                    try {
                        await (window.gatewallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(212)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.gatewallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(212),
                                            chainName: 'Mapo-Test',
                                            nativeCurrency: {
                                                name: 'MAPO',
                                                symbol: 'MAPO',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://testnet-rpc.maplabs.io'],
                                            blockExplorerUrls: ['https://testnet.maposcan.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else if (parseInt(store.state.networkIDstring) === 223) {
                    try {
                        await (window.gatewallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(223)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.gatewallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(223),
                                            chainName: 'B² Mainnet',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.bsquared.network'],
                                            blockExplorerUrls: ['https://explorer.bsquared.network']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else {
                    try {
                        await (window.gatewallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(1502)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.gatewallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(1502),
                                            chainName: 'Bevm-Test',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://canary-testnet.bevm.io'],
                                            blockExplorerUrls: ['https://scan-canary-testnet.bevm.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                }
            } else {
                if (parseInt(store.state.networkIDstring) === 22776) {
                    try {
                        await (window.gatewallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(22776)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.gatewallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(22776),
                                            chainName: 'MAPO-Main',
                                            nativeCurrency: {
                                                name: 'MAPO',
                                                symbol: 'MAPO',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.maplabs.io'],
                                            blockExplorerUrls: ['https://maposcan.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else if (parseInt(store.state.networkIDstring) === 223) {
                    try {
                        await (window.gatewallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(223)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.gatewallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(223),
                                            chainName: 'B² Mainnet',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc.bsquared.network'],
                                            blockExplorerUrls: ['https://explorer.bsquared.network']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                } else {
                    try {
                        await (window.gatewallet).request({
                            method: 'wallet_switchEthereumChain',
                            params: [{
                                chainId: Web3.utils.numberToHex(11501)
                            }]
                        })
                    } catch (e) {
                        if ((e).code === 4902) {
                            try {
                                await (window.gatewallet).request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        {
                                            chainId: Web3.utils.numberToHex(11501),
                                            chainName: 'BEVM-Main',
                                            nativeCurrency: {
                                                name: 'BTC',
                                                symbol: 'BTC',
                                                decimals: 18
                                            },
                                            rpcUrls: ['https://rpc-mainnet-1.bevm.io'],
                                            blockExplorerUrls: ['https://scan-mainnet.bevm.io']
                                        }
                                    ]
                                })
                            } catch (err) {
                                console.log(err);
                            }
                        } else if ((e).code === 4001) {
                            return;
                        }
                    }
                }
            }
        }
    }

    console.log('Connection successful');
};


const popupPrompts = (type, hashCode) => {
    store.commit('setState', {
        toastShow: true,
        toastShowType: type,
        transactionHash: hashCode
    })
};


// export const getMaxFeeGas = (): { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber } => {
//     return {
//         maxFeePerGas: BigNumber.from(5).mul(Math.pow( x: 10, y: 7)),
//         maxPriorityFeePerGas: BigNumber.from(0)
//     }
// }


export const getBalance = async (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await new queryWeb3.eth.getBalance(address);
            resolve(res);
        } catch (err) {
            reject(err);
        }
    })
}

export const getIndexBlock = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await new queryWeb3.eth.getBlockNumber();
            resolve(res);
        } catch (err) {
            reject(err);
        }
    })
}


export const toNftMint = async (address, params, price) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiBitseaNft, address), "nftmint", params,
        {
            from: store.state.accounts[0],
            value: price,
            // gas: 2000000,
            // gasPrice: 500000000 // 0.5 gwei
        }
    );
}

export const getInfoPici = async (address, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiBitseaNft, address), "info_pici", params);
}

export const getCountPici = async (address, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiBitseaNft, address), "count_pici", params);
}

export const getCountNftNum = async (address, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiBitseaNft, address), "getCountNftNum", params);
}

export const getNftInfo = async (address, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiBitseaNft, address), "nftInfo", params);
}

export const getIsWhiteList = async (address, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiBitseaNft, address), "_whiteList", params);
}

export const getMyNft = async (address, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiBitseaNft, address), "mynft", params);
}

export const getMaxClaimNum = async (address, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiClaimNft, address), "max_claim_count", params);
}

export const getClaimNum = async (address, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiClaimNft, address), "getClaimNum", params);
}

export const getAllClaimNum = async (address, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiClaimNft, address), "getAllClaimNum", params);
}

export const nftclaim = async (address, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiClaimNft, address), "nftclaim", params,
        {
            from: store.state.accounts[0],
        }
    );
}


export const onSell = async (params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiShop, shopAddress), "onsell", params);
}

export const allOnSell = async (params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiShop, shopAddress), "allonsell", params);
}

export const offSell = async (params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiShop, shopAddress), "offsell", params);
}

export const allOffSell = async (params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiShop, shopAddress), "alloffsell", params);
}

export const onBuy = async (params, price) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiShop, shopAddress), "onbuy", params, {
        from: store.state.accounts[0],
        value: price,
        // gas: 2000000,
        // gasPrice: 500000000 // 0.5 gwei
    });
}

export const allOnBuy = async (params, price) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiShop, shopAddress), "allonbuy", params, {
        from: store.state.accounts[0],
        value: price,
        // gas: 2000000,
        // gasPrice: 500000000 // 0.5 gwei
    });
}

export const getShopBalanceOf = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiShop, shopAddress), "balanceOf", params);
}

export const getGoodsList = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiShop, shopAddress), "goodsList", params);
}

export const getGoodsInfo = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiShop, shopAddress), "goodsInfo", params);
}

export const getPtransfer = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiShop, shopAddress), "p_transfer", params);
}

export const getListingPrice = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiShop, shopAddress), "getListingPrice", params);
}

export const getNftTransaction = async (fromBlock, toBlock, nftAddress, transactionType) => {
    return new Promise(async (resolve, reject) => {
        try {
            new queryWeb3.eth.Contract(abiShop, shopAddress).getPastEvents('SHOPNFT', {
                filter: { contractNft: nftAddress, transactionType: transactionType },
                fromBlock: fromBlock,
                toBlock: toBlock
            }).then(function(events){
                resolve(events);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        } catch (e) {
            console.log(e);
        }
    });
}

export const getNftAllTransaction = async (fromBlock, toBlock, nftAddress, transactionType) => {
    return new Promise(async (resolve, reject) => {
        try {
            new queryWeb3.eth.Contract(abiShop, shopAddress).getPastEvents('SHOPNFT', {
                filter: transactionType === 0 ? { contractNft: nftAddress } : { contractNft: nftAddress, transactionType: transactionType },
                fromBlock: fromBlock,
                toBlock: toBlock
            }).then(function(events){
                resolve(events);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        } catch (e) {
            console.log(e);
        }
    });
}


export const getMintWhiteList = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "mintWhiteList", params);
}

export const getMintPici = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "mintPici", params);
}

export const getMintNftInfo = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "mintNftInfo", params);
}

export const getBalanceOf = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "balanceOf", params);
}

export const myNftNum = async (params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiERC404, monsterLandAddress), "myNftNum", params);
}

export const getIsApprovedForAll = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "isApprovedForAll", params);
}

export const getTokenURI = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "tokenURI", params);
}

export const getMyNftByPage = async (params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC404, monsterLandAddress), "myNft", params);
}

export const toSetApprovalForAll = async (params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC404, monsterLandAddress), "setApprovalForAll", params);
}

export const userMint = async (params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC404, monsterLandAddress), "userMint", params);
}


export const erc20BasicPublicCall = async (address, methods, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC20, address), methods, params);
}
export const erc20BasicPublicSend = async (address, methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC20, address), methods, params);
}


export const erc721BasicPublicCall = async (address, methods, params) => {
    return await publicCall(new queryWeb3.eth.Contract(abiERC721, address), methods, params);
}
export const erc721BasicPublicSend = async (address, methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC721, address), methods, params);
}


// public send
export const publicSend = async (contract, methods, params = [], send_json = {
    from: store.state.accounts[0],
    // gas: 2000000
    // gasPrice: 500000000 // 0.5 gwei
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit("setState", {
                toastShow: true,
                toastShowType: 0,
            });

            // console.log(contract, methods, params);

            await contract.methods[methods](...params).call(send_json);

            const res = await contract.methods[methods](...params).send(
                send_json
            ).on('transactionHash', function (hash) {
                popupPrompts(1, hash);
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash);
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    })
}

// public call
export const publicCall = async (contract, methods, params = []) => {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log(contract, methods, params);

            const res = await contract.methods[methods](...params).call({ from: store.state.accounts[0] });
            resolve(res);
        } catch (err) {
            reject(err);
        }
    })
}


export {
    initWeb3,
    initWeb3hooks,
    initParams,
    popupPrompts,
    initQueryWeb3,
}
