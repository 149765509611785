<template>
    <div id="bitsea_bottom_page" class="bitsea_bottom_page">
<!--        <img src="../assets/images/logo_bottom.png" class="bitsea_bottom_01"/>-->
        <div class="bitsea_bottom_all">
            <!-- <div class="bitsea_bottom_01">
                Terms & Conditions | Privacy Policy
            </div> -->
            <div class="bitsea_bottom_02">
                <a href="https://twitter.com/Bitsea_NFT" target="_blank">
                    <img src="../assets/images/X_icon.png"/>
                </a>

<!--                <img src="../assets/images/discord_icon.png"/>-->
<!--                <img src="../assets/images/telegram_icon.png"/>-->

                <a href="https://medium.com/@bitsea_nft/bitsea-the-btc-layer2-nft-platform-bb24821ad054" target="_blank">
                    <img src="../assets/images/book_icon.png"/>
                </a>
            </div>
            <div class="bitsea_bottom_03">
                © 2024 BitSea, all rights reserved.
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Button",
  components: {},
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="less">
.bitsea_bottom_page {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100px;
  /*background-color: #ffb358;*/
  /*background-image: linear-gradient(#ffb358, #ff9538);*/
  background: rgba(19, 19, 19, 0.85);
  .bitsea_bottom_all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 80%;
    margin-left: 10%;
    .bitsea_bottom_01 {
      /*width: 53px;*/
      height: 42px;
      line-height: 42px;
      color: white;
      font-size: 18px;
      // font-family: Arial;
      font-weight: 400;
    }
    .bitsea_bottom_02 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      img {
        cursor: pointer;
        margin: 0 16px;
        opacity: 0.5;
      }
      img:hover {
        opacity: 1;
      }
    }
    .bitsea_bottom_03 {
      /*width: 205px;*/
      height: 42px;
      cursor: default;
      /*border-top: 1px solid #A14E2D;*/
      color: rgba(255,255,255,.2);
      font-size: 16px;
      // font-family: Arial;
      font-weight: 400;
      line-height: 42px;
    }
  }
}
</style>
