<template>
    <div class="connect_wallet_assembly">
        <el-dialog
                :visible.sync="$store.state.connectWalletShow && $store.state.accounts.length === 0"
                :show-close="false"
                :close-on-click-modal="false"
                :modal-append-to-body="true"
                :append-to-body="true"
                :width="$store.state.accounts.length === 0 ? '460px' : '500px'">
            <div :class="$store.state.accounts.length === 0 ? 'connect_wallet_div' : 'connect_wallet_div dialog_div_width'">
                <div class="wallet_div_top">
                    <div>{{$store.state.accounts.length === 0 ? 'Connect Wallet' : 'Account'}}</div>
                    <img @click="closeWallet()" src="../assets/images/icon_close.png" style="width: 30px">
                </div>
                <div class="wallet_div_info">
                    <div class="connect_div" v-if="$store.state.accounts.length === 0">
                        <div class="connect_div_all" @click="initWeb3(2)">
                            <img src="../assets/images/gate.png" style="width: 60px;">
                            <div>Gate</div>
                        </div>
                        <div class="connect_div_all" @click="initWeb3(0)">
                            <img src="../assets/images/okb.png" style="width: 60px;">
                            <div>OKX</div>
                        </div>
                        <div class="connect_div_all" @click="initWeb3(1)">
                            <img src="../assets/images/metaMask.png" style="width: 60px;">
                            <div>Metamask</div>
                        </div>
                    </div>

                    <div class="account_div" v-if="false">
                        <div class="account_div01">
                            <div class="account_div011">
                                <div class="account_div0111" v-show="$store.state.walletType === 0">
                                    <img src="../assets/images/okb.png">
                                    <div>OKX Connected</div>
                                </div>
                                <div class="account_div0111" v-show="$store.state.walletType === 1">
                                    <img src="../assets/images/metaMask.png">
                                    <div>Metamask Connected</div>
                                </div>
                                <div class="account_div0111" v-show="$store.state.walletType === 2">
                                    <img src="../assets/images/gate.png">
                                    <div>Gate Connected</div>
                                </div>
                                <div class="account_div0112">
                                    <div class="change_div" @click="$store.dispatch('logOut')">Change</div>
                                    <div class="disconnect_div" @click="$store.dispatch('logOut')">Disconnect</div>
                                </div>
                            </div>
                            <div class="account_div012">
                                <div style="margin-left: 23px;">{{ cutAccount($store.state.accounts[0], 6, false) }}</div>
                            </div>
                        </div>
                        <div class="account_div02">
                            <div class="button_div" @click="copyValue($store.state.accounts[0])">
                                <img src="../assets/images/copy.png">
                                <div>Copy Address</div>
                            </div>
                            <a :href="'https://'+ chainExplorerUrl +'/address/' + $store.state.accounts[0]" target="_blank">
                                <div class="button_div">
                                    <img src="../assets/images/icon_search.png" style="width: 32px;">
                                    <div>View in Browser</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import * as metaMaskTools from "../tools/chain";
    import toolUtils from "../tools/toolUtils";
    import {
        ipfsUrl,
        openUrlSLD,
        bitseaNfts,
        controlAddress,
        shopAddress,
        monsterLandAddress
    } from "../tools/projectConfig";
    import { cutAccount } from '../tools/index';
    import transfer from "../tools/transfer";

    export default {
        name: "ConnectWallet",
        components: {},
        data() {
            return {
                chainExplorerUrl: null
            }
        },
        watch: {},
        created() {
            this.initUrl();
        },
        mounted() {
            this.initUrl();
        },
        destroyed() {
        },
        methods: {
            initWeb3(type) {
                this.$store.commit('setState', {
                    walletType: parseInt(type)
                })
                sessionStorage.setItem("walletType", type);
                metaMaskTools.initWeb3().then(() => {});
            },
            closeWallet() {
                this.$store.commit('setState', {
                    connectWalletShow: false
                })
                transfer.$emit("closeMyProfile");
            },
            copyValue(val) {
                if (val) {
                    toolUtils.copyValue(val);
                    this.$message.success('Copy successful!');
                } else {
                    this.$message.error('No content copied!');
                }
            },
            cutAccount(text, num, isXing) {
                return cutAccount(text, num, isXing);
            },
            initUrl() {
                if (sessionStorage.getItem("walletType")) {
                    this.$store.commit('setState', {
                        walletType: parseInt(sessionStorage.getItem("walletType"))
                    })
                }

                this.chainExplorerUrl = openUrlSLD;
            }
        }
    }
</script>

<style lang="less">
    .connect_wallet_assembly {
        position: absolute;
    }

    .connect_wallet_div {
        width: 400px;
        height: 370px;
        position: relative;
        .wallet_div_top {
            div {
                position: absolute;
                top: 5px;
                left: 10px;
                height: 26px;
                font-size: 20px;
                // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #B55704;
                line-height: 26px;
            }
            img {
                position: absolute;
                top: 5px;
                right: 10px;
                cursor: pointer;
                opacity: 0.8;
            }
        }
        .wallet_div_info {
            width: 100%;
            .connect_div {
                width: 100%;
                position: absolute;
                top: 85px;
                .connect_div_all:hover {
                    color: rgba(255, 255, 255, 1);
                    background: #f79825;
                    // border: 3px solid #ffd074;
                }
                .connect_div_all {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    width: 98%;
                    height: 70px;
                    background: rgb(162, 100, 0);
                    border-radius: 12px;
                    // border: 3px solid #fcdcb8;
                    margin: 20px 0;
                    font-size: 16px;
                    // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #fff;
                    line-height: 21px;
                    img {
                        margin-left: 20px;
                        // height: 10px;
                        scale: 0.8;
                    }
                    div {
                        margin-left: 20px;
                    }
                }
            }

            .account_div {
                width: 100%;
                position: absolute;
                top: 65px;
                .account_div01 {
                    width: 100%;
                    height: 120px;
                    background: #ffebca;
                    border-radius: 12px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .account_div011 {
                        font-size: 14px;
                        // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 18px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        .account_div0111 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            img {
                                width: 25px;
                                margin: 25px 10px 10px 20px;
                            }
                            div {
                                margin: 25px 15px 10px 5px;
                            }
                        }
                        .account_div0112 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            div {
                                margin: 25px 1px 10px 10px;
                            }
                            .change_div {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                            .disconnect_div {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                    .account_div012 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        height: 26px;
                        font-size: 20px;
                        // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 26px;
                        img {
                            width: 25px;
                            margin: 25px 10px 10px 20px;
                        }
                        div {
                            margin: 25px 15px 10px 5px;
                        }
                    }
                }
                .account_div02 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    .button_div {
                        cursor: pointer;
                        width: 200px;
                        height: 60px;
                        background: #fff9ed;
                        box-shadow: -4px 4px 0px 0px #141315;
                        border-radius: 12px;
                        border: 3px solid #141315;
                        font-size: 16px;
                        // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 21px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: center;
                        margin-top: 35px;
                        div {
                            margin-left: 10px;
                            color: #B55704;
                        }
                    }
                }
            }
        }
    }
    .dialog_div_width {
        width: 440px !important;
        height: 290px !important;

    }

    .el-dialog__wrapper {
        @media (max-width: 767px){
            zoom: 0.9 !important;
        }
    }
</style>
