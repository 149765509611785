import { debug } from "../config";
import store from "../store";



/* main */
// Map
let openUrlSLD = 'maposcan.io';
let bitseaNfts = [
    {
        name: "Hiveswap Memorial NFT",
        img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png", // https://bafybeidllybh4esxucep7izwxobghxcsyv6fbpvgh4q63ongy3hweveb3m.ipfs.nftstorage.link
        isTokenUri: false,
        tokenUri: "",
        address: '0x57C46671A01Aad9359D97F844916CCAb4bf6713c',
        fromBlock: 11607556,
        details: {
            title: "Hiveswap Memorial NFT",
            img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
            describe: "To commemorate HiveSwap reaching a TVL of $135,381,588, HiveSwap has collaborated with a Bitcoin L2 NFT platform to launch the HiveSwap Memorial NFT.\n" +
                "\n" +
                "A Remarkable Milestone for HiveSwap $135,381,588 TVL achieved! This marks #HiveSwap as the top swap in TVL in the Bitcoin L2 landscape.",
            snapshot: "To commemorate HiveSwap reaching a TVL of $135,381,588, HiveSwap has collaborated with a Bitcoin L2 NFT platform to launch the HiveSwap Memorial NFT.\n" +
                "A Remarkable Milestone for HiveSwap $135,381,588 TVL achieved! This marks #HiveSwap as the top swap in TVL in the Bitcoin L2 landscape.",
            linka: null,
            linkb: null,
            linkc: null,
        },
        isLaunchpad: true
    },
    // {
    //     name: "MAPO:The Awakening Of MAP",
    //     img: "https://bafybeidcve3pgw3r2aezszgod7dlzymcusmvpoczpe7m7b2qhlqlodwzia.ipfs.nftstorage.link/TaomNFT.png",
    //     isTokenUri: false,
    //     tokenUri: "",
    //     address: '0x790F77F6507e0da03197dd761Db61597AA9Cb29A',
    //     fromBlock: 11874380,
    //     details: {
    //         title: "MAPO:The Awakening Of MAP",
    //         img: "https://bafybeidcve3pgw3r2aezszgod7dlzymcusmvpoczpe7m7b2qhlqlodwzia.ipfs.nftstorage.link/TaomNFT.png",
    //         describe: "To celebrate the completion of the main network switch of Map Protocol, Map Protocol collaborated with Bitsea to launch the commemorative NFT \"MAPO:The Awakening Of MAP\".",
    //         snapshot: "To celebrate the completion of the main network switch of Map Protocol, Map Protocol collaborated with Bitsea to launch the commemorative NFT \"MAPO:The Awakening Of MAP\".",
    //         linka: null,
    //         linkb: null,
    //         linkc: null,
    //     },
    //     isLaunchpad: false
    // },
];
let controlAddress = '0x7779349E2624F5aE5db0DD2Ad21B3a14fd978d1B'; // 11352565
let shopAddress = '0x5D95Db251BF9cb6914D3941086098E1A284A8F45';
let monsterLandAddress = '';
let ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";



const setProjectConfig = async () => {
    // Map
    openUrlSLD = 'maposcan.io';
    bitseaNfts = [
        {
            name: "Hiveswap Memorial NFT",
            img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
            isTokenUri: false,
            tokenUri: "",
            address: '0x57C46671A01Aad9359D97F844916CCAb4bf6713c',
            fromBlock: 11607556,
            details: {
                title: "Hiveswap Memorial NFT",
                img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                describe: "To commemorate HiveSwap reaching a TVL of $135,381,588, HiveSwap has collaborated with a Bitcoin L2 NFT platform to launch the HiveSwap Memorial NFT.\n" +
                    "\n" +
                    "A Remarkable Milestone for HiveSwap $135,381,588 TVL achieved! This marks #HiveSwap as the top swap in TVL in the Bitcoin L2 landscape.",
                snapshot: "To commemorate HiveSwap reaching a TVL of $135,381,588, HiveSwap has collaborated with a Bitcoin L2 NFT platform to launch the HiveSwap Memorial NFT.\n" +
                    "A Remarkable Milestone for HiveSwap $135,381,588 TVL achieved! This marks #HiveSwap as the top swap in TVL in the Bitcoin L2 landscape.",
                linka: null,
                linkb: null,
                linkc: null,
            },
            isLaunchpad: true
        },
        // {
        //     name: "MAPO:The Awakening Of MAP",
        //     img: "https://bafybeidcve3pgw3r2aezszgod7dlzymcusmvpoczpe7m7b2qhlqlodwzia.ipfs.nftstorage.link/TaomNFT.png",
        //     isTokenUri: false,
        //     tokenUri: "",
        //     address: '0x790F77F6507e0da03197dd761Db61597AA9Cb29A',
        //     fromBlock: 11874380,
        //     details: {
        //         title: "MAPO:The Awakening Of MAP",
        //         img: "https://bafybeidcve3pgw3r2aezszgod7dlzymcusmvpoczpe7m7b2qhlqlodwzia.ipfs.nftstorage.link/TaomNFT.png",
        //         describe: "To celebrate the completion of the main network switch of Map Protocol, Map Protocol collaborated with Bitsea to launch the commemorative NFT \"MAPO:The Awakening Of MAP\".",
        //         snapshot: "To celebrate the completion of the main network switch of Map Protocol, Map Protocol collaborated with Bitsea to launch the commemorative NFT \"MAPO:The Awakening Of MAP\".",
        //         linka: null,
        //         linkb: null,
        //         linkc: null,
        //     },
        //     isLaunchpad: false
        // },
    ];
    controlAddress = '0x7779349E2624F5aE5db0DD2Ad21B3a14fd978d1B'; // 11352565
    shopAddress = '0x5D95Db251BF9cb6914D3941086098E1A284A8F45';
    monsterLandAddress = '';
    ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";

    // Bevm
    if (parseInt(store.state.networkIDstring) === 11501) {
        openUrlSLD = 'scan-mainnet.bevm.io';
        bitseaNfts = [
            {
                name: "MonsterLand404",
                img: "https://bafybeicp7gbeeg7sjlaafi2sivbgrntlaotyai6znidv5f2kx5tdqzhj4a.ipfs.nftstorage.link/MonsterLandLogo.png",
                isTokenUri: false,
                tokenUri: "",
                address: '0x037b572661137537611F707CFA3F206994767e23',
                fromBlock: 435770,
                details: {
                    title: "MonsterLand404",
                    img: "https://bafybeicp7gbeeg7sjlaafi2sivbgrntlaotyai6znidv5f2kx5tdqzhj4a.ipfs.nftstorage.link/MonsterLandLogo.png",
                    describe: "MonsterLand404 was the first ERC404 project created on BEVM. 5999 adorable monsters are the owners on MonsterLand, waiting for those who are destined to receive them for free.",
                    snapshot: "MonsterLand404 was the first ERC404 project created on BEVM. 5999 adorable monsters are the owners on MonsterLand, waiting for those who are destined to receive them for free.",
                    linka: null,
                    linkb: null,
                    linkc: "https://twitter.com/MonsterLand404",
                },
                isLaunchpad: true
            },
            {
                name: "SeaBox",
                img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                isTokenUri: false,
                tokenUri: "",
                address: '0x5731F83cABB63D099d7D491E159EC59eF6Aa4469',
                fromBlock: 346722,
                details: {
                    title: "SeaBox",
                    img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                    describe: "Seabox, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                        "\n" +
                        "Seabox is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                    snapshot: "Seabox, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                    linka: null,
                    linkb: null,
                    linkc: "https://x.com/bitsea_nft",
                },
                isLaunchpad: true
            },
            {
                name: "GenesisBox",
                img: "https://self.4everland.store/box.jpg",
                isTokenUri: false,
                tokenUri: "",
                address: '0x09Ff8E49D0EA411A3422ed95E8f5497D4241F532',
                fromBlock: 227488,
                details: {
                    title: "GenesisBox",
                    img: "https://self.4everland.store/box.jpg",
                    describe: "The Genesis Box is another precious gift following the BEVM whitepaper NFT. The design philosophy behind the Genesis Box is to express deep gratitude to every supporter and invite them to become co-creators of this new era." +
                        "Possessing such a valuable keepsake, participants not only become an inseparable part of the bevm community but will also enjoy a special status and identity recognition throughout the journey of network development.",
                    snapshot: "The Genesis Box is another precious gift following the BEVM whitepaper NFT.",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: false
            },
            {
                name: "RyeharvestBadge",
                img: require("../assets/images/NFT/BEVM/RyeharvestBadge/RyeharvestBadgeLogo.jpg"), // https://dfbb2e9e217e747f452f84b6e899a570.ipfs.4everland.link/ipfs/bafybeid5u5bqpvmtztsektosoztt4nbchtkmugrja6kamd5dte6awuki3q
                isTokenUri: false,
                tokenUri: "",
                address: '0x2d72Cea26311B9e96edF2EB2423993d280592e7E',
                fromBlock: 1744655,
                details: {
                    title: "RyeharvestBadge",
                    img: require("../assets/images/NFT/BEVM/RyeharvestBadge/RyeharvestBadgeLogo.jpg"),
                    describe: "RyeHarvest, a native synthetic stable coin protocol for bitcoin, is revolutionizing the DeFi ecosystem with a new synthetic stablecoin protocol on Bitcoin’s layer. Early adopters will be rewarded with the exclusive RyeHarvest Badge. Join us and be a part of this innovative journey!",
                    snapshot: "RyeHarvest, a native synthetic stable coin protocol for bitcoin, is revolutionizing the DeFi ecosystem with a new synthetic stablecoin protocol on Bitcoin’s layer. Early adopters will be rewarded with the exclusive RyeHarvest Badge. Join us and be a part of this innovative journey!",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: false
            },
        ];
        controlAddress = '0xbb6CA5D606945F37d3d855C7f2FEb2fC3BD8D9dD';
        shopAddress = '0x3D378C2a6a7fF1Bf57dcFa73497Dc6eFB984B4f0';
        monsterLandAddress = '0x037b572661137537611F707CFA3F206994767e23';
        ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";
    }

    // B²
    if (parseInt(store.state.networkIDstring) === 223) {
        openUrlSLD = 'explorer.bsquared.network';
        bitseaNfts = [
            {
                name: "BlockHeadz",
                img: "https://bafybeig2eal44hun6vns474itpa4qos3rvvsprr7o54vqtxx4palcgtbgi.ipfs.nftstorage.link/BlockHeadz.png",
                isTokenUri: true,
                tokenUri: "https://b2-static.bsquared.network/assets/nft/images/BlockHeadz/",
                address: '0x066466d7eaa56b60aaf0436dbda6f92db7bd2468',
                fromBlock: 1126761,
                details: {
                    title: "BlockHeadz",
                    img: "https://bafybeig2eal44hun6vns474itpa4qos3rvvsprr7o54vqtxx4palcgtbgi.ipfs.nftstorage.link/BlockHeadz.png",
                    describe: "BlockHeadz, the genesis NFT collection of B² Network, symbolizes the dawn of our journey to scale Bitcoin. These diverse figurines will accompany and empower us throughout this epic journey.",
                    snapshot: "BlockHeadz, the genesis NFT collection of B² Network, symbolizes the dawn of our journey to scale Bitcoin. These diverse figurines will accompany and empower us throughout this epic journey.",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: false
            },
            {
                name: "BitcoinLoot NFT",
                img: "https://bafybeig2eal44hun6vns474itpa4qos3rvvsprr7o54vqtxx4palcgtbgi.ipfs.nftstorage.link/BitcoinLoot.png",
                isTokenUri: true,
                tokenUri: "https://static.bitcoinloot.co/loot/loot_",
                address: '0x2704ec05c4491ebf54c9740396dd2e24f5f43ba2',
                fromBlock: 1126761,
                details: {
                    title: "BitcoinLoot NFT",
                    img: "https://bafybeig2eal44hun6vns474itpa4qos3rvvsprr7o54vqtxx4palcgtbgi.ipfs.nftstorage.link/BitcoinLoot.png",
                    describe: "The BitcoinLoot Genesis Collection represents the foundational essence of the BitcoinLoot universe, encapsulating the genesis of boundless creativity and decentralized innovation. Each meticulously crafted NFT within this collection serves as a gateway to a realm of endless possibilities, empowering holders to not only possess a piece of digital history but also to become architects of the decentralized BTC universe itself.",
                    snapshot: "The BitcoinLoot Genesis Collection represents the foundational essence of the BitcoinLoot universe, encapsulating the genesis of boundless creativity and decentralized innovation. Each meticulously crafted NFT within this collection serves as a gateway to a realm of endless possibilities, empowering holders to not only possess a piece of digital history but also to become architects of the decentralized BTC universe itself.",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: false
            },
        ];
        controlAddress = '0xd11ea23a430E49D85FFeA0519A7244c4150ae901'; // 1126761
        shopAddress = '0xFFb7878669c38DEc6E1F9569C15aE1E4dF8C9390';
        monsterLandAddress = '';
        ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";
    }



    /* test */
    if (debug) {
        // Map
        openUrlSLD = 'testnet.maposcan.io';
        bitseaNfts = [
            {
                name: "Hiveswap Memorial NFT",
                img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                isTokenUri: false,
                tokenUri: "",
                address: '0x30FE2Bcd97765161438e6B98337753C23a712F8F',
                fromBlock: 9143915,
                details: {
                    title: "Hiveswap Memorial NFT",
                    img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                    describe: "Hiveswap Memorial NFT, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                        "\n" +
                        "Hiveswap Memorial NFT is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                    snapshot: "Hiveswap Memorial NFT, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: true
            },
            {
                name: "Hiveswap Memorial NFT",
                img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                isTokenUri: false,
                tokenUri: "",
                address: '0x5A9a23B244cAa6E0f1Bbf900F57839Ad3ccDb986',
                fromBlock: 9143915,
                details: {
                    title: "Hiveswap Memorial NFT",
                    img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                    describe: "Hiveswap Memorial NFT, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                        "\n" +
                        "Hiveswap Memorial NFT is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                    snapshot: "Hiveswap Memorial NFT, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: true
            },
            {
                name: "Hiveswap Memorial NFT",
                img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                isTokenUri: false,
                tokenUri: "",
                address: '0x67B36d5127815922587398ea027b181D05572BcD',
                fromBlock: 9143915,
                details: {
                    title: "Hiveswap Memorial NFT",
                    img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                    describe: "Hiveswap Memorial NFT, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                        "\n" +
                        "Hiveswap Memorial NFT is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                    snapshot: "Hiveswap Memorial NFT, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                    linka: null,
                    linkb: null,
                    linkc: null,
                },
                isLaunchpad: true
            },
        ];
        controlAddress = '0x860b924Af7790b021a2Bf88EfA47Bada7aEe2025';
        shopAddress = '0xaeB54f1E4832b73F8861A775c79094C8713B921A';
        monsterLandAddress = '';
        ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";

        // Bevm
        if (parseInt(store.state.networkIDstring) === 1502) {
            openUrlSLD = 'scan-canary-testnet.bevm.io';
            bitseaNfts = [
                // {
                //     name: "SeaNew",
                //     img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                //     isTokenUri: false,
                //     tokenUri: "",
                //     address: '0xf5b162E72B06b3ecc3A98Bd0A842f4Ed5f3530CF',
                //     fromBlock: 1590552,
                //     details: {
                //         title: "SeaNew",
                //         img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                //         describe: "SeaNew, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                //             "\n" +
                //             "Seabox is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                //         snapshot: "SeaNew, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                //         linka: null,
                //         linkb: null,
                //         linkc: "https://x.com/bitsea_nft",
                //     },
                //     isLaunchpad: true
                // },
                {
                    name: "SeaNft",
                    img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                    isTokenUri: false,
                    tokenUri: "",
                    address: '0xCc81e7270fE7FC07e043a539A2D816aB2ddAeB42',
                    fromBlock: 1589514,
                    details: {
                        title: "SeaNft",
                        img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                        describe: "SeaNft, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                            "\n" +
                            "Seabox is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                        snapshot: "SeaNft, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                        linka: null,
                        linkb: null,
                        linkc: "https://x.com/bitsea_nft",
                    },
                    isLaunchpad: true
                },
                {
                    name: "SeaBox",
                    img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                    isTokenUri: false,
                    tokenUri: "",
                    address: '0x7666882B2615D862AB5aF36d4E3abf96edeBe6A7',
                    fromBlock: 1576284,
                    details: {
                        title: "SeaBox",
                        img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                        describe: "Seabox, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                            "\n" +
                            "Seabox is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                        snapshot: "Seabox, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                        linka: null,
                        linkb: null,
                        linkc: "https://x.com/bitsea_nft",
                    },
                    isLaunchpad: true
                },
                // {
                //     name: "SeaBox-Claim",
                //     img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                //     isTokenUri: false,
                //     tokenUri: "",
                //     address: '0x0cd3Ce52d2f878C11ba473A42825b9154971c404',
                //     fromBlock: 1576057,
                //     details: {
                //         title: "SeaBox-Claim",
                //         img: "https://bafybeihtpgqurefn5nqhulttw7dnooxj5wdwevbsd7iceig3w5lnjpm3my.ipfs.nftstorage.link/mnggiflab-compressed-9dad9d0c6819e2f8fa6563aa4fdda0f8.gif",
                //         describe: "Seabox, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                //             "\n" +
                //             "Seabox is the genesis NFT collection of Bitsea. Owners of Seabox will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                //         snapshot: "Seabox, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                //         linka: null,
                //         linkb: null,
                //         linkc: "https://x.com/bitsea_nft",
                //     },
                //     isLaunchpad: true
                // },
                // {
                //     name: "MonsterLand",
                //     img: "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/1.png",
                //     isTokenUri: false,
                //     tokenUri: "",
                //     address: '0x6f14e94813aC41521Fabce136294be8f667BCE98',
                //     fromBlock: 1575875,
                //     details: {
                //         title: "MonsterLand",
                //         img: "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/1.png",
                //         describe: "MonsterLand was the first ERC404 project created on BEVM. 10000 adorable monsters are the owners on MonsterLand, waiting for those who are destined to receive them for free.",
                //         snapshot: "MonsterLand was the first ERC404 project created on BEVM. 10000 adorable monsters are the owners on MonsterLand, waiting for those who are destined to receive them for free.",
                //         linka: null,
                //         linkb: null,
                //         linkc: null,
                //     },
                //     isLaunchpad: true
                // }
            ];
            controlAddress = '0x8Ff07E46Aa688D76bF742c645ea2173F1e16FA82';
            shopAddress = '0x299ED6b2Ba017E37c5c5C6949157219Ef38C11e2';
            monsterLandAddress = '0x6f14e94813aC41521Fabce136294be8f667BCE98';
            ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";
        }

        // B²
        if (parseInt(store.state.networkIDstring) === 223) {
            openUrlSLD = 'explorer.bsquared.network';
            bitseaNfts = [
                {
                    name: "SeaNft",
                    img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                    isTokenUri: false,
                    tokenUri: "",
                    address: '0xaE90195f6fC981e1F17Ce90F36cfeB1ce62bB44b',
                    fromBlock: 484548,
                    details: {
                        title: "SeaNft",
                        img: "https://bafybeid4dsywt7jwwlfu3oox7o5y4voh7ncajrzpavb5ulnshlaebp2ire.ipfs.nftstorage.link/HiveSwapNFT.png",
                        describe: "SeaNft, launched by Bitsea, is the first NFT based on the Bitcoin Layer 2 network, total supply:10,000. As the first NFT platform on Bitcoin Layer 2, Bitsea is characterized by lightning-fast transaction speeds and minimal gas fee. The platform aims to expand the Bitcoin ecosystem and is compatible with EVM and its ecosystem. Bitsea focuses on advancing the innovation of NFT asset protocol standards, establishing new market trading norms, and promoting the growth and prosperity of the NFT sector in the Bitcoin Layer 2 network.\n" +
                            "\n" +
                            "SeaNft is the genesis NFT collection of Bitsea. Owners of SeaNft will receive airdrop rewards from Bitsea ecosystem partnership projects, and opportunities for additional airdrops of Bitsea. These privileges not only offer potential for asset appreciation but also enable holders to deeply engage and benefit from the evolving Bitcoin Layer2 network ecosystem.",
                        snapshot: "SeaNft, limited to 10,000, is the genesis series of NFTs launched by Bitsea on the Bitcoin Layer 2",
                        linka: null,
                        linkb: null,
                        linkc: null,
                    },
                    isLaunchpad: true
                },
            ];
            controlAddress = '0x5731F83cABB63D099d7D491E159EC59eF6Aa4469'; // 484548
            shopAddress = '0x646a2B745F96fc86965Abf8756007feA8a6F4f68';
            monsterLandAddress = '';
            ipfsUrl = "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/";
        }
    }
}



export {
    ipfsUrl,
    openUrlSLD,
    bitseaNfts,
    controlAddress,
    shopAddress,
    monsterLandAddress,
    setProjectConfig
}
