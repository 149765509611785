<template>
    <div class="meta_dialog">
        <el-dialog
            :visible.sync="$store.state.toastShow"
            :show-close="false"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            width="460px">
            <div class="meta_dialog_div">
                <div class="meta_dialog_close" @click="hide()">
                    <img src="../assets/images/icon_close.png"/>
                </div>
                <div class="meta_dialog01" v-show="$store.state.walletType === 0">
                    <img v-show="$store.state.toastShowType === 0" class="meta_dialog_img01" src="../assets/images/okb.png"/>
                    <img v-show="$store.state.toastShowType === 1" class="meta_dialog_img02" src="../assets/images/okb.png"/>
                    <img v-show="$store.state.toastShowType === 2" class="meta_dialog_img03" src="../assets/images/okb.png"/>
                    <img v-show="$store.state.toastShowType === 3" class="meta_dialog_img04" src="../assets/images/okb.png"/>
                    <img v-show="$store.state.toastShowType === 4" class="meta_dialog_img05" src="../assets/images/okb.png"/>
                    <img v-show="$store.state.toastShowType === 5" class="meta_dialog_img06" src="../assets/images/okb.png"/>
                </div>
                <div class="meta_dialog01" v-show="$store.state.walletType === 1">
                    <img v-show="$store.state.toastShowType === 0" class="meta_dialog_img01" src="../assets/images/metaMask.png"/>
                    <img v-show="$store.state.toastShowType === 1" class="meta_dialog_img02" src="../assets/images/metaMask.png"/>
                    <img v-show="$store.state.toastShowType === 2" class="meta_dialog_img03" src="../assets/images/metaMask.png"/>
                    <img v-show="$store.state.toastShowType === 3" class="meta_dialog_img04" src="../assets/images/metaMask.png"/>
                    <img v-show="$store.state.toastShowType === 4" class="meta_dialog_img05" src="../assets/images/metaMask.png"/>
                    <img v-show="$store.state.toastShowType === 5" class="meta_dialog_img06" src="../assets/images/metaMask.png"/>
                </div>
                <div class="meta_dialog01" v-show="$store.state.walletType === 2">
                    <img v-show="$store.state.toastShowType === 0" class="meta_dialog_img01" src="../assets/images/gate.png"/>
                    <img v-show="$store.state.toastShowType === 1" class="meta_dialog_img02" src="../assets/images/gate.png"/>
                    <img v-show="$store.state.toastShowType === 2" class="meta_dialog_img03" src="../assets/images/gate.png"/>
                    <img v-show="$store.state.toastShowType === 3" class="meta_dialog_img04" src="../assets/images/gate.png"/>
                    <img v-show="$store.state.toastShowType === 4" class="meta_dialog_img05" src="../assets/images/gate.png"/>
                    <img v-show="$store.state.toastShowType === 5" class="meta_dialog_img06" src="../assets/images/gate.png"/>
                </div>
                <div class="meta_dialog02">
                    <div v-show="$store.state.toastShowType === 0">Confirm transaction in wallet…</div>
                    <div v-show="$store.state.toastShowType === 1">Transaction submitted.</div>
                    <div v-show="$store.state.toastShowType === 2">Operation Successful!</div>
                    <div v-show="$store.state.toastShowType === 3">Ineligible.</div>
                    <div v-show="$store.state.toastShowType === 4">Operation Canceled.</div>
                    <div v-show="$store.state.toastShowType === 5">Act +2</div>
                </div>
                <div class="meta_dialog03">
                    <a v-show="$store.state.toastShowType !== 0 && $store.state.toastShowType !== 3 && $store.state.toastShowType !== 4" :href="'https://'+ chainExplorerUrl +'/tx/' + $store.state.transactionHash" target="_blank">
                        <div>View</div>
                    </a>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        ipfsUrl,
        openUrlSLD,
        bitseaNfts,
        controlAddress,
        shopAddress,
        monsterLandAddress
    } from "../tools/projectConfig";

    export default {
        name: "MetaDialog",
        components: {},
        data() {
            return {
                chainExplorerUrl: null,
            }
        },
        watch: {},
        created() {
            this.initUrl();
        },
        mounted() {
            this.initUrl();
        },
        destroyed() {
        },
        methods: {
            hide() {
                this.$store.commit('setState', {
                    toastShow: false
                })
            },
            initUrl() {
                this.chainExplorerUrl = openUrlSLD;
            }
        }
    }
</script>

<style lang="less">
    .meta_dialog {
        position: absolute;
    }

    .meta_dialog_div {
        width: 400px;
        height: 340px;
        position: relative;
        .meta_dialog_close {
            position: absolute;
            right: 5px;
            top: 0;
            cursor: pointer;
            width: 30px;
            height: 30px;
            text-align: center;
            img {
                width: 30px;
                height: 30px;
                margin-top: 1px;
            }
        }
        .meta_dialog01 {
            img {
                width: 120px;
                height: 120px;
            }
            .meta_dialog_img01 {
                position: absolute;
                top: 55px;
                left: calc((100% - 116px) / 2);
            }
            .meta_dialog_img02 {
                position: absolute;
                top: 75px;
                left: calc((100% - 116px) / 2);
            }
            .meta_dialog_img03 {
                position: absolute;
                top: 30px;
                left: calc((100% - 116px) / 2);
            }
            .meta_dialog_img04 {
                position: absolute;
                top: 45px;
                left: calc((100% - 116px) / 2);
            }
            .meta_dialog_img05 {
                position: absolute;
                top: 55px;
                left: calc((100% - 116px) / 2);
            }
            .meta_dialog_img06 {
                position: absolute;
                top: 55px;
                left: calc((100% - 116px) / 2);
            }
        }
        .meta_dialog02 {
            position: absolute;
            bottom: 110px;
            width: 100%;
            div {
                width: 100%;
                height: 21px;
                text-align: center;
                font-size: 20px;
                // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #eb8d29;
                line-height: 21px;
            }
        }
        .meta_dialog03 {
            position: absolute;
            bottom: 30px;
            left: calc((100% - 200px) / 2);
            a {
                div {
                    width: 200px;
                    height: 60px;
                    background: #F9F7F3;
                    box-shadow: -4px 4px 0px 0px #141315;
                    border-radius: 12px;
                    border: 3px solid #141315;
                    font-size: 18px;
                    // font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    text-align: center;
                    line-height: 56px;
                }
            }
        }
    }

    .el-dialog {
        margin-top: 30vh !important;
        background: #fff0 !important;
        border-radius: 25px !important;
        .el-dialog__header {
            display: none !important;
        }
        .el-dialog__body {
            padding: 30px 30px !important;
            background: linear-gradient(90deg, #181818 0%, #201d18 35%, #392f23 100%) !important;
            border-radius: 20px !important;
            border: 2px solid #7f4902 !important;
        }
    }

    .el-popup-parent--hidden {
        padding-right: 0 !important;
    }
</style>
